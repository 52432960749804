import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledInput } from "../../../../styles/InputStyles";
import InputWrapper from "../../../../components/inputWrapper/InputWrapper";
import { StyledButton } from "../../../../styles/ButtonStyles";
import Card from "../../../../components/card/Card";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../styles/LayoutStyles";

function AnnouncementPageView({
  announcement,
  announcementData,
  onTitleChange,
  onActiveFromChange,
  onActiveUntilChange,
  onDescriptionChange,
  onSubmit,
  submitErrors,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return !announcement || announcementData.fetched ? (
    <Card header={translate("main.data")}>
      <InputWrapper
        label={translate("validation.attributes.title")}
        error={submitErrors?.errors?.title?.[0]}
        required
      >
        <StyledInput
          type="text"
          placeholder={translate("validation.placeholders.title.announcement")}
          value={announcementData.title}
          onChange={onTitleChange}
        />
      </InputWrapper>

      <StyledFlexLayout margin={`${theme.spacing.normal} 0px 0px 0px`}>
        <StyledFlexLayoutItem>
          <InputWrapper
            label={translate("validation.attributes.active_from")}
            error={submitErrors?.errors?.active_from?.[0]}
            required
          >
            <StyledInput
              type="datetime-local"
              value={announcementData.activeFrom}
              onChange={onActiveFromChange}
            />
          </InputWrapper>
        </StyledFlexLayoutItem>

        <StyledFlexLayoutItem>
          <InputWrapper
            label={translate("validation.attributes.active_until")}
            error={submitErrors?.errors?.active_until?.[0]}
            required
          >
            <StyledInput
              type="datetime-local"
              id="active_until"
              name="active_until"
              value={announcementData.activeUntil}
              onChange={onActiveUntilChange}
            />
          </InputWrapper>
        </StyledFlexLayoutItem>
      </StyledFlexLayout>

      <InputWrapper
        margin={`${theme.spacing.normal} 0px`}
        label={translate("validation.attributes.description")}
        error={submitErrors?.errors?.description?.[0]}
        required
      >
        <StyledInput
          as={"textarea"}
          rows="4"
          placeholder={translate(
            "validation.placeholders.description.announcement"
          )}
          value={announcementData.description}
          onChange={onDescriptionChange}
        />
      </InputWrapper>

      <StyledButton onClick={onSubmit}>{translate("main.save")}</StyledButton>
    </Card>
  ) : (
    ""
  );
}

export default AnnouncementPageView;
