import styled from "styled-components";

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
`;

export const StyledInputRequirement = styled.span`
  color: ${(props) => props.theme.colors.accent.normal};
`;

export const StyledInputError = styled.small`
  font-size: ${(props) => props.theme.font.size.small};
  color: ${(props) => props.theme.colors.error};
`;
