export default class DataTableRowAction {
  constructor() {
    this.icon = null;
    this.callback = null;
    this.disableOn = null;
  }

  /**
   * This method will create a new instance of this class
   * @returns {DataTableRowAction}
   */
  static create() {
    return new DataTableRowAction();
  }

  /**
   * Used as a visual for the row action
   * @param {function(row) : IconDefinition} icon
   * @returns {this}
   */
  setIcon(icon) {
    this.icon = icon;
    return this;
  }

  /**
   * Used as a callback method whenever the action is pressed
   * @param {function(row)} callback
   * @returns {this}
   */
  setCallback(callback) {
    this.callback = callback;
    return this;
  }

  /**
   * Used for disabling this row action on specific rows
   * @param {function(row) : boolean} disableOn
   * @returns {this}
   */
  setDisableOn(disableOn) {
    this.disableOn = disableOn;
    return this;
  }
}
