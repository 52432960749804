import React, { useContext, useEffect, useState } from "react";
import EVAApi from "../apis/EVAApi";
import { useCookies } from "react-cookie";
import { useCallback } from "react";

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [cookies, setCookie, removeCookie] = useCookies([
    "user_id",
    "jwt_token",
  ]);
  const [auth, setAuth] = useState({ user: null, token: null });
  const [fetchingUser, setFetchingUser] = useState(false);

  /**
   * This method will fetch the user from the API
   */
  const fetchUser = useCallback(async () => {
    if (cookies.user_id) {
      setFetchingUser(true);

      try {
        const response = await EVAApi.get(`users/${cookies.user_id}`, {
          params: getAuthFetchParams(),
        });
        if (response.status === 200 && response.data?.success) {
          setAuth({ user: response.data.data, token: cookies.jwt_token });
        }
      } catch (error) {
        logout();
      } finally {
        setFetchingUser(false);
      }
    }
  }, [cookies]);

  /**
   * UseEffect to fetch the user on initialization
   */
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  /**
   * UseEffect to update the document title on user update
   */
  useEffect(() => {
    document.title =
      "EVA 3" + (auth.user ? " - " + auth.user.company.name : "");
  }, [auth.user]);

  /**
   * This method will try to login to user with the given credentials
   *
   * @param {string} email
   * @param {string} password
   * @returns {JSONResponse}
   */
  async function login(email, password) {
    try {
      const response = await EVAApi.post(
        "login",
        {
          email: email,
          password: password,
        },
        {
          params: getAuthFetchParams(),
        }
      );

      if (response.status === 200 && response.data?.success) {
        const responseUser = response.data.data.user;
        const responseToken = response.data.data.token;

        setCookie("user_id", responseUser.id, { path: "/" });
        setCookie("jwt_token", responseToken, { path: "/" });
        setAuth({ user: responseUser, token: responseToken });
      }

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  }

  /**
   * This method will try to login the user into a company with the specified id
   *
   * @param {int} id
   * @returns {JSONResponse}
   */
  async function loginToCompany(id) {
    try {
      const response = await EVAApi.post(`login/${id}`, null, {
        params: getAuthFetchParams(),
      });
      if (response.status === 200 && response.data?.success) {
        setAuth({ ...auth, user: response.data.data });
      }
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  }

  /**
   * This method will try to logout the current authenticated user
   *
   * @returns {JSONResponse}
   */
  async function logout() {
    try {
      const response = await EVAApi.post("logout");
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    } finally {
      setAuth({ user: null, token: null });
      removeCookie("user_id", { path: "/" });
      removeCookie("jwt_token", { path: "/" });
    }
  }

  /**
   * This method will return the auth fetch params
   * @returns {object}
   */
  function getAuthFetchParams() {
    return {
      with_company_data: true,
      with_roles_data: true,
      with_permissions_data: true,
    };
  }

  return (
    <AuthContext.Provider
      value={{ auth, login, loginToCompany, logout, fetchingUser, fetchUser }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
