import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledInput } from "../../../../styles/InputStyles";
import InputWrapper from "../../../../components/inputWrapper/InputWrapper";
import Card from "../../../../components/card/Card";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../styles/LayoutStyles";
import { StyledButton } from "../../../../styles/ButtonStyles";

function CompanyPageView({
  company,
  companyData,
  onNameChange,
  onContactChange,
  onContactDetailsChange,
  onSubmit,
  submitErrors,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return !company || companyData.fetched ? (
    <Card header={translate("main.data")}>
      <InputWrapper
        label={translate("validation.attributes.name")}
        error={submitErrors?.errors?.name?.[0]}
        required
      >
        <StyledInput
          type="text"
          placeholder={translate("validation.placeholders.name.company")}
          value={companyData.name}
          onChange={onNameChange}
        />
      </InputWrapper>

      <StyledFlexLayout margin={`${theme.spacing.normal} 0px`}>
        <StyledFlexLayoutItem>
          <InputWrapper
            label={translate("validation.attributes.contact")}
            error={submitErrors?.errors?.contact?.[0]}
            required
          >
            <StyledInput
              type="text"
              placeholder={translate("validation.placeholders.contact")}
              value={companyData.contact}
              onChange={onContactChange}
            />
          </InputWrapper>
        </StyledFlexLayoutItem>

        <StyledFlexLayoutItem>
          <InputWrapper
            label={translate("validation.attributes.contact_details")}
            error={submitErrors?.errors?.contact_details?.[0]}
          >
            <StyledInput
              type="text"
              placeholder={translate("validation.placeholders.contact_details")}
              value={companyData.contactDetails}
              onChange={onContactDetailsChange}
            />
          </InputWrapper>
        </StyledFlexLayoutItem>
      </StyledFlexLayout>

      <StyledButton onClick={onSubmit}>{translate("main.save")}</StyledButton>
    </Card>
  ) : (
    ""
  );
}

export default CompanyPageView;
