import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import EVAApi from "../../../../../apis/EVAApi";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import RolePageView from "./RolePageView";
import { useAuthContext } from "../../../../../context/AuthContext";

function RolePage() {
  const ROLES_PAGE_URL = "/dashboard/users/roles";

  const navigate = useNavigate();
  const authContext = useAuthContext();
  const { role } = useParams();
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();

  const [permissions, setPermissions] = useState({
    fetched: false,
    records: [],
  });
  const [roleData, setRoleData] = useState({
    fetched: false,
    name: "",
    permissions: [],
  });
  const [submitErrors, setSubmitErros] = useState(null);

  /**
   * This method will fetch the initial data from the API
   */
  const fetchRoleData = useCallback(async () => {
    try {
      const response = await EVAApi.get(`roles/${role}`);
      if (response.status === 200 && response.data?.data) {
        setRoleData({
          fetched: true,
          name: response.data.data.name,
          permissions: response.data.data.permissions,
        });
      }
    } catch (error) {
      navigate(ROLES_PAGE_URL);
    }
  }, [role, navigate]);

  /**
   * Fetch the allergens from the API
   */
  const fetchPermissions = useCallback(async () => {
    try {
      const response = await EVAApi.get("permissions");
      if (response.status === 200 && response.data?.data) {
        setPermissions({ fetched: true, records: response.data.data.records });
      }
    } catch (error) {
      navigate(ROLES_PAGE_URL);
    }
  }, [navigate]);

  /**
   * This effect will initialize the page
   */
  useEffect(() => {
    setDashboardTitle(
      role
        ? translate("entities.actions.edit", [
            { key: "entities", value: translate("entities.role") },
          ])
        : translate("entities.actions.create", [
            { key: "entities", value: translate("entities.role") },
          ])
    );

    fetchPermissions();

    if (role) {
      fetchRoleData();
    }
  }, [role, fetchRoleData, fetchPermissions, setDashboardTitle, translate]);

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setRoleData({ ...roleData, name: e.target.value });
  }

  /**
   * This method will update the name on input change
   * @param {object[]} options
   */
  function onPermissionsChange(options) {
    setRoleData({
      ...roleData,
      permissions: options.map((option) => option.value),
    });
  }

  /**
   * This method will create and return the FormData object for submission
   * @returns {FormData}
   */
  function getSubmitData() {
    const formData = new FormData();
    formData.append("name", roleData.name);
    roleData.permissions.forEach((permission, index) => {
      formData.append(`permissions[${index}]`, permission);
    });
    if (role) {
      formData.append("_method", "PUT");
    }
    return formData;
  }

  /**
   * This method will submit the form to the EVA API and handles its response
   */
  async function onSubmit() {
    setSubmitErros(null);

    try {
      const response = await EVAApi.request({
        url: role ? `roles/${role}` : "roles",
        method: "POST",
        data: getSubmitData(),
      });

      if (response.status === 200 && response.data?.success) {
        navigate(ROLES_PAGE_URL);

        //Refetch the authenticated user if the user has the role we just changed
        if (
          role &&
          authContext.auth.user.roles.find(
            (userRole) => userRole.id === parseInt(role)
          )
        ) {
          authContext.fetchUser();
        }
      }
    } catch (error) {
      if (error.response?.data) {
        setSubmitErros(error.response.data);
      }
    }
  }

  return (
    <RolePageView
      role={role}
      roleData={roleData}
      permissions={permissions}
      onNameChange={onNameChange}
      onPermissionsChange={onPermissionsChange}
      onSubmit={onSubmit}
      submitErrors={submitErrors}
    />
  );
}

export default RolePage;
