import styled from "styled-components";

export const StyledDataTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledDataTableHeaderContainer = styled.div`
  display: flex;
`;

export const StyledDataTableHeaderSearchContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: center;
`;

export const StyledDataTableHeaderActionContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledDataTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-radius: ${(props) => props.theme.radius.default};
  overflow: hidden;
`;

export const StyledDataTableHeaderTr = styled.tr`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
`;

export const StyledDataTableTh = styled.th`
  border-left: 1px solid ${(props) => props.theme.colors.primary.darkest};
  border-right: 1px solid ${(props) => props.theme.colors.primary.darkest};
  padding: ${(props) => props.theme.spacing.normal};
  text-align: left;
  width: ${(props) => props.width};
  color: ${(props) =>
    props.active
      ? props.theme.colors.accent.normal
      : props.theme.colors.on.primary};
`;

export const StyledDataTableBodyTr = styled.tr`
  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors.primary.normal};
    color: ${(props) => props.theme.colors.on.primary};
  }

  &:nth-child(even) {
    background-color: ${(props) => props.theme.colors.primary.light};
    color: ${(props) => props.theme.colors.on.primary};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.darkest};
    color: ${(props) => props.theme.colors.on.primary};
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
  }
`;

export const StyledDataTableTd = styled.td`
  padding: ${(props) => props.theme.spacing.normal};
  text-align: ${(props) => props.alignText};

  &:not(:first-child):not(:last-child) {
    border-left: 1px solid ${(props) => props.theme.colors.primary.darkest};
  }

  &:not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.colors.primary.darkest};
  }
`;

export const StyledDataTableActionsTd = styled(StyledDataTableTd)`
  border-left: 0px;
`;

export const StyledDataTableThLabel = styled.span`
  position: ${(props) => (props.hide ? "absolute" : "initial")};
  opacity: ${(props) => (props.hide ? 0 : 1)};
  pointer-events: ${(props) => (props.hide ? "none" : "all")};
`;
