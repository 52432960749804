import styled from "styled-components";

export const StyledImageInputContainer = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: ${(props) => props.theme.radius.default};
  overflow: hidden;
  position: relative;
`;

export const StyledImageInputImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

export const StyledImageInputInput = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledImageInputOverlay = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all ${(props) => props.theme.transition.duration.default};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 1;
  }
`;
