import styled from "styled-components";
import { StyledIconButton } from "../../styles/ButtonStyles";

export const StyledDashboardContainer = styled.div`
  height: 100vh;
  display: flex;
`;

export const StyledDashboardNavContainer = styled.aside`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  border-right: 1px solid ${(props) => props.theme.colors.primary.lightest};
  width: ${(props) => props.width};
  transition: all ${(props) => props.theme.transition.duration.default};
`;

export const StyledDashboardNavLogoContainer = styled.div`
  height: 80px;
  padding: ${(props) => props.theme.spacing.large};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledDashboardNavLogo = styled.img`
  max-height: 100%;
  margin: 0px auto 0px 0px;
  border-radius: 0px;
  opacity: ${(props) => (props.hide ? 0 : 1)};
  transition: all ${(props) => props.theme.transition.duration.default};
`;

export const StyledDashboardNavFoldoutButton = styled(StyledIconButton)`
  position: absolute;
  right: 0px;
  transform: translateX(50%);
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};

  &:hover {
    border-color: transparent;
    color: ${(props) => props.theme.colors.on.accent};
  }
`;

export const StyledDashboardNav = styled.nav`
  height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
  padding: ${(props) => props.theme.spacing.large} 0px;
`;

export const StyledDashboardMainContainer = styled.div`
  flex: 1;
`;

export const StyledDashboardHeader = styled.header`
  height: 80px;
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
  padding: 0px ${(props) => props.theme.spacing.large};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.large};
`;

export const StyledDashboardHeaderUserContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledDashboardHeaderUser = styled.p`
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const StyledDashboardMain = styled.main`
  height: calc(100vh - 80px);
  padding: ${(props) => props.theme.spacing.large};
  overflow-y: auto;
`;
