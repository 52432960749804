import React, { useContext, useState } from "react";
import { ThemeProvider } from "styled-components";
import DarkTheme from "../themes/DarkTheme";

const ThemeContext = React.createContext();

export function AutoThemeProvider({ children }) {
  const [theme, setTheme] = useState(
    JSON.parse(localStorage.getItem("theme")) || DarkTheme
  );

  function updateTheme(newTheme) {
    setTheme(newTheme);
    localStorage.setItem("theme", JSON.stringify(newTheme));
  }

  return (
    <ThemeContext.Provider value={{ updateTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}

export function useThemeContext() {
  return useContext(ThemeContext);
}
