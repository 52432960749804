import {
  faBackwardFast,
  faBackwardStep,
  faCaretDown,
  faCaretUp,
  faForwardFast,
  faForwardStep,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "styled-components";
import {
  StyledAltIconButton,
  StyledButton,
  StyledTextButton,
} from "../../styles/ButtonStyles";
import { StyledInput } from "../../styles/InputStyles";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../styles/LayoutStyles";
import Select from "../select/Select";
import {
  StyledDataTable,
  StyledDataTableActionsTd,
  StyledDataTableBodyTr,
  StyledDataTableContainer,
  StyledDataTableHeaderActionContainer,
  StyledDataTableHeaderTr,
  StyledDataTableTd,
  StyledDataTableTh,
  StyledDataTableThLabel,
} from "./DataTableStyles";
import { useLanguageContext } from "../../context/LanguageContext";

function DataTable({
  columns,
  data,
  getData,
  rowActions,
  headerActions,
  defaultOrderBy,
  defaultSort = "desc",
  paginationOptions = ["10", "25", "50", "100"],
  paginationOption = "100",
}) {
  const theme = useTheme();

  // Basic DataTable variables
  const { translate } = useLanguageContext();

  //Determine the orderable columns
  const orderableColumns = useMemo(() => {
    return columns
      .filter((col) => col.orderable === true)
      .map((col) => col.name);
  }, [columns]);

  // Filtering state object
  const [filtering, setFiltering] = useState({
    where: [],
    per_page: paginationOption,
    page: 1,
    order_by:
      defaultOrderBy ??
      (orderableColumns.length > 0 ? orderableColumns[0] : null),
    sort: defaultSort,
  });

  //Reference to prevent the useffect from fetching new data on filter changes
  const preventFilteringFetch = useRef(false);

  /**
   * This effect will initialize the dataTable
   */
  useEffect(() => {
    if (preventFilteringFetch.current === true) {
      preventFilteringFetch.current = false;
      return;
    }

    getData(filtering);
  }, [getData, filtering]);

  /**
   * This method will return what the width of the search input of the specified column should be
   * @param {DataTableColumn} column
   * @returns {string}
   */
  function getSearchInputWidth(column) {
    const inputElement = document.getElementById(column.name + "_label");
    if (inputElement) {
      return inputElement.offsetWidth + 25 + "px";
    } else {
      return "100px";
    }
  }

  /**
   * This method will return if the given column is currently in search mode or not
   * @param {DataTableColumn} column
   * @returns {boolean}
   */
  function isColumnSearching(column) {
    return filtering.where.find((item) => item.column === column.name);
  }

  /**
   * This method will return the value if the search input of the given column
   * @param {DataTableColumn} column
   * @returns {string}
   */
  function getSearchInputValue(column) {
    const whereItem = filtering.where.find(
      (item) => item.column === column.name
    );
    return whereItem ? whereItem.value : "";
  }

  /**
   * This method will update the search input of the given column to the given value
   * @param {DataTableColumn} column
   * @param {string} value
   */
  function updateSearchInputValue(column, value) {
    const newWhere = filtering.where.slice();
    const newWhereItem = newWhere.find((item) => item.column === column.name);
    if (newWhereItem) {
      newWhereItem.value = value;

      preventFilteringFetch.current = true;
      setFiltering({ ...filtering, where: newWhere });
    }
  }

  /**
   * This method will add the given column to the search columns
   * @param {DataTableColumn} column
   */
  function addSearchInput(column) {
    const newWhere = filtering.where.slice();
    if (!newWhere.find((item) => item.column === column.name)) {
      newWhere.push({ column: column.name, value: "" });

      preventFilteringFetch.current = true;
      setFiltering({ ...filtering, where: newWhere });
    }
  }

  /**
   * This method will remove the given column from the search columns
   * @param {DataTableColumn} column
   */
  function removeSearchInput(column) {
    const whereIndex = filtering.where.findIndex(
      (item) => item.column === column.name
    );
    if (whereIndex > -1) {
      const newWhere = filtering.where.slice();
      newWhere.splice(whereIndex, 1);

      setFiltering({ ...filtering, where: newWhere });
    }
  }

  /**
   * This method will refresh the dataTable data on Enter press
   * @param {any} event
   */
  function handleSearchInputKeyPressed(event) {
    if (event.key === "Enter") {
      setFiltering({ ...filtering });
    }
  }

  /**
   * This method will refresh the DataTable data on search input focus loss
   */
  function handleSearchInputFocusLoss() {
    setFiltering({ ...filtering });
  }

  //Return the DataTable visuals
  return (
    <StyledDataTableContainer>
      <StyledFlexLayout alignItems="end">
        <StyledFlexLayout gap="7.5px" alignItems="end">
          {translate("main.max")}

          {/* Pagination Count Select */}
          <Select
            options={paginationOptions.map((option) => {
              return { label: option, value: option };
            })}
            value={{
              label: filtering.per_page,
              value: filtering.per_page,
            }}
            onChange={(option) => {
              setFiltering({ ...filtering, page: 1, per_page: option.value });
            }}
          />

          {translate("main.results").toLowerCase()}
        </StyledFlexLayout>

        {/* DataTable Header Actions */}
        {headerActions?.length > 0 ? (
          <StyledDataTableHeaderActionContainer>
            {headerActions.map((headerAction, headerActionIndex) =>
              !headerAction.disabled ? (
                <StyledButton
                  height={theme.container.size.normal}
                  width={!headerAction.label ? theme.container.size.normal : ""}
                  padding={
                    !headerAction.label ? "0px" : `0px ${theme.spacing.normal}`
                  }
                  key={headerActionIndex}
                  onClick={() => headerAction.callback()}
                >
                  <FontAwesomeIcon icon={headerAction.icon} />
                  {headerAction.label}
                </StyledButton>
              ) : (
                ""
              )
            )}
          </StyledDataTableHeaderActionContainer>
        ) : (
          ""
        )}
      </StyledFlexLayout>

      {/* DataTable Table */}
      <StyledDataTable>
        {/* DataTable Headers */}
        <thead>
          <StyledDataTableHeaderTr>
            {columns.map((col, index) => (
              <StyledDataTableTh
                key={index}
                active={filtering.order_by === col.name}
                style={col.headerStyling}
              >
                <StyledFlexLayout>
                  <StyledFlexLayoutItem>
                    {/* Column search input */}
                    {isColumnSearching(col) ? (
                      <StyledInput
                        type="text"
                        placeholder={col.label}
                        width="100%"
                        minWidth={getSearchInputWidth(col)}
                        value={getSearchInputValue(col)}
                        onChange={(e) => {
                          updateSearchInputValue(col, e.target.value);
                        }}
                        onKeyUp={(e) => {
                          handleSearchInputKeyPressed(e);
                        }}
                        onBlur={() => {
                          handleSearchInputFocusLoss();
                        }}
                      />
                    ) : (
                      ""
                    )}

                    {/* Colum header label */}
                    <StyledDataTableThLabel
                      hide={isColumnSearching(col)}
                      id={col.name + "_label"}
                    >
                      {col.label}
                    </StyledDataTableThLabel>
                  </StyledFlexLayoutItem>

                  <StyledFlexLayout gap="5px">
                    {col.orderable ? (
                      <StyledTextButton
                        onClick={() => {
                          setFiltering({
                            ...filtering,
                            page: 1,
                            order_by: col.name,
                            sort: filtering.sort === "asc" ? "desc" : "asc",
                          });
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            filtering.sort === "asc" ? faCaretUp : faCaretDown
                          }
                          size="sm"
                        />
                      </StyledTextButton>
                    ) : (
                      ""
                    )}

                    {col.searchable ? (
                      <StyledTextButton
                        onClick={() => {
                          if (!isColumnSearching(col)) {
                            addSearchInput(col);
                          } else {
                            removeSearchInput(col);
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={isColumnSearching(col) ? faXmark : faSearch}
                          size="xs"
                        />
                      </StyledTextButton>
                    ) : (
                      ""
                    )}
                  </StyledFlexLayout>
                </StyledFlexLayout>
              </StyledDataTableTh>
            ))}

            {rowActions?.length > 0 ? (
              <StyledDataTableTh width="0px">
                {translate("main.actions")}
              </StyledDataTableTh>
            ) : (
              ""
            )}
          </StyledDataTableHeaderTr>
        </thead>

        {/* DataTable Body */}
        <tbody>
          {data?.records
            ? data.records.map((row, rowIndex) => (
                <StyledDataTableBodyTr key={rowIndex}>
                  {/* DataTable Row Data */}
                  {columns.map((col, colIndex) => (
                    <StyledDataTableTd key={colIndex}>
                      {col.selector(row)}
                    </StyledDataTableTd>
                  ))}

                  {/* DataTable Row Actions */}
                  {rowActions?.length > 0 ? (
                    <StyledDataTableActionsTd>
                      <StyledFlexLayout>
                        {rowActions.map((rowAction, rowActionIndex) =>
                          !rowAction.disableOn || !rowAction.disableOn(row) ? (
                            <StyledTextButton
                              key={rowActionIndex}
                              onClick={() => rowAction.callback(row)}
                            >
                              <FontAwesomeIcon icon={rowAction.icon(row)} />
                            </StyledTextButton>
                          ) : (
                            ""
                          )
                        )}
                      </StyledFlexLayout>
                    </StyledDataTableActionsTd>
                  ) : (
                    ""
                  )}
                </StyledDataTableBodyTr>
              ))
            : ""}

          {/* No Results */}
          {data == null || data.records.length <= 0 ? (
            <StyledDataTableBodyTr>
              <StyledDataTableTd alignText="center" colSpan="100%">
                {translate("main.no_results")}
              </StyledDataTableTd>
            </StyledDataTableBodyTr>
          ) : (
            ""
          )}
        </tbody>
      </StyledDataTable>

      {data?.pagination ? (
        <StyledFlexLayout>
          {/* DataTable Pagination Info */}
          <small>
            {translate("pagination.results", [
              { key: "first_item", value: data.pagination.first_item },
              { key: "last_item", value: data.pagination.last_item },
              { key: "total", value: data.pagination.total },
            ])}
          </small>

          {/* DataTable Pagination Buttons */}
          {data.pagination.total_pages > 1 ? (
            <StyledFlexLayout margin="0px 0px 0px auto" gap="5px">
              <StyledAltIconButton
                disabled={filtering.page <= 1}
                onClick={() => {
                  setFiltering({ ...filtering, page: 1 });
                }}
              >
                <FontAwesomeIcon icon={faBackwardFast} />
              </StyledAltIconButton>

              <StyledAltIconButton
                disabled={filtering.page <= 1}
                onClick={() => {
                  setFiltering({ ...filtering, page: filtering.page - 1 });
                }}
              >
                <FontAwesomeIcon icon={faBackwardStep} />
              </StyledAltIconButton>

              <StyledAltIconButton
                disabled={filtering.page >= data.pagination.total_pages}
                onClick={() => {
                  setFiltering({ ...filtering, page: filtering.page + 1 });
                }}
              >
                <FontAwesomeIcon icon={faForwardStep} />
              </StyledAltIconButton>

              <StyledAltIconButton
                disabled={filtering.page >= data.pagination.total_pages}
                onClick={() => {
                  setFiltering({
                    ...filtering,
                    page: data.pagination.total_pages,
                  });
                }}
              >
                <FontAwesomeIcon icon={faForwardFast} />
              </StyledAltIconButton>
            </StyledFlexLayout>
          ) : (
            ""
          )}
        </StyledFlexLayout>
      ) : (
        ""
      )}
    </StyledDataTableContainer>
  );
}

export default DataTable;
