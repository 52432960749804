import styled from "styled-components";

export const StyledButton = styled.button`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.disabled
      : props.theme.colors.accent.normal};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.on.disabled
      : props.theme.colors.on.accent};
  padding: ${(props) =>
    props.padding ? props.padding : props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.default};
  transition: all ${(props) => props.theme.transition.duration.default};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  height: ${(props) => props.height};
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.accent.dark};
    color: ${(props) => props.theme.colors.on.accent};
  }
`;

export const StyledAltButton = styled(StyledButton)`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.disabled
      : props.theme.colors.primary.lightest};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.on.disabled
      : props.theme.colors.on.primary};
`;

export const StyledIconButton = styled(StyledButton)`
  padding: 0px;
  width: ${(props) => props.theme.container.size.normal};
  height: ${(props) => props.theme.container.size.normal};
`;

export const StyledAltIconButton = styled(StyledIconButton)`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.disabled
      : props.theme.colors.primary.lightest};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.on.disabled
      : props.theme.colors.on.primary};
`;

export const StyledTextButton = styled.button`
  transition: all ${(props) => props.theme.transition.duration.default};
  color: ${(props) => props.color};

  &:hover {
    color: ${(props) => props.theme.colors.accent.dark};
  }
`;
