import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledInput } from "../../../../styles/InputStyles";
import InputWrapper from "../../../../components/inputWrapper/InputWrapper";
import { StyledButton } from "../../../../styles/ButtonStyles";
import Card from "../../../../components/card/Card";
import { StyledFlexLayout } from "../../../../styles/LayoutStyles";
import Select from "../../../../components/select/Select";
import { useAuthContext } from "../../../../context/AuthContext";
import { userHasPermission } from "../../../../helpers/Auth";

function EmployeePageView({
  employee,
  employeeData,
  allergens,
  onNameChange,
  onEmailChange,
  onPresentChange,
  onAllergensChange,
  onSubmit,
  submitErrors,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();
  const authContext = useAuthContext();

  //Define the allergen input options
  const allergensInputOptions = allergens.records.map((allergen) => {
    return {
      value: allergen.id,
      label: translate(`allergens.${allergen.name}`),
    };
  });

  //Define the present input options
  const presentInputOptions = [
    { value: true, label: translate("main.yes") },
    { value: false, label: translate("main.no") },
  ];

  const employeeUpdateSelfMode =
    employee && !userHasPermission(authContext.auth.user, "employees.update");

  return allergens.fetched && (!employee || employeeData.fetched) ? (
    <Card header={translate("main.data")}>
      <StyledFlexLayout
        direction="column"
        margin={`0px 0px ${theme.spacing.normal} 0px`}
      >
        <InputWrapper
          label={translate("validation.attributes.name")}
          required
          error={submitErrors?.errors?.name?.[0]}
        >
          <StyledInput
            type="text"
            placeholder={translate("validation.placeholders.name.person")}
            value={employeeData.name}
            onChange={onNameChange}
            disabled={employeeUpdateSelfMode}
          />
        </InputWrapper>

        <InputWrapper
          label={translate("validation.attributes.email")}
          required
          error={submitErrors?.errors?.email?.[0]}
        >
          <StyledInput
            type="email"
            placeholder={translate("validation.placeholders.email")}
            value={employeeData.email}
            onChange={onEmailChange}
            disabled={employeeUpdateSelfMode}
          />
        </InputWrapper>

        <InputWrapper
          label={translate("validation.attributes.present")}
          alignItems="start"
          error={submitErrors?.errors?.present?.[0]}
        >
          <Select
            options={presentInputOptions}
            value={presentInputOptions.find(
              (option) => option.value === employeeData.present
            )}
            onChange={onPresentChange}
            disabled={employeeUpdateSelfMode}
          />
        </InputWrapper>

        <InputWrapper
          label={translate("entities.allergens")}
          margin={`0px 0px ${theme.spacing.normal} 0px`}
        >
          <Select
            isMulti
            options={allergensInputOptions}
            value={allergensInputOptions.filter((option) =>
              employeeData.allergens.find(
                (employeeAllergen) => employeeAllergen === option.value
              )
            )}
            onChange={onAllergensChange}
          />
        </InputWrapper>
      </StyledFlexLayout>

      <StyledButton onClick={onSubmit}>{translate("main.save")}</StyledButton>
    </Card>
  ) : (
    ""
  );
}

export default EmployeePageView;
