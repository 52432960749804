import {
  StyledInputError,
  StyledInputRequirement,
  StyledInputWrapper,
} from "./InputWrapperStyles";

function InputWrapper({
  label,
  required,
  error,
  children,
  margin,
  alignItems,
}) {
  return (
    <StyledInputWrapper margin={margin} alignItems={alignItems}>
      <label>
        {label}{" "}
        {required ? <StyledInputRequirement>*</StyledInputRequirement> : ""}
      </label>

      {children}

      {error ? <StyledInputError>{error}</StyledInputError> : ""}
    </StyledInputWrapper>
  );
}

export default InputWrapper;
