export default class DataTableColumn {
  constructor() {
    this.name = "";
    this.label = "";
    this.selector = null;
    this.searchable = true;
    this.orderable = true;
    this.headerStyling = {};
  }

  /**
   * Creates and returns a new instance of this class
   * @returns {DataTableColumn}
   */
  static create() {
    return new DataTableColumn();
  }

  /**
   * The name of the column in the database
   * @param {string} name
   * @returns {this}
   */
  setName(name) {
    this.name = name;
    return this;
  }

  /**
   * The visual display label for this column, will be displayed in the headers
   * @param {string} label
   * @returns {this}
   */
  setLabel(label) {
    this.label = label;
    return this;
  }

  /**
   * Used for getting the correct value in the row to display
   * @param {function(row) : any} selector
   * @returns {this}
   */
  setSelector(selector) {
    this.selector = selector;
    return this;
  }

  /**
   * Determines if the column will be used when searching the database
   * @param {boolean} searchable
   * @returns {this}
   */
  setSearchable(searchable) {
    this.searchable = searchable;
    return this;
  }

  /**
   * Determines if the database results can be ordered based on this column
   * @param {boolean} orderable
   * @returns {this}
   */
  setOrderable(orderable) {
    this.orderable = orderable;
    return this;
  }

  /**
   * Used for applying extra styling to the header of this column
   * @param {object} headerStyling
   * @returns
   */
  setHeaderStyling(headerStyling) {
    this.headerStyling = headerStyling;
    return this;
  }
}
