import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useLanguageContext } from "../../../../context/LanguageContext";
import EmployeesPageView from "./EmployeesPageView";

function EmployeesPage() {
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();

  useEffect(() => {
    setDashboardTitle(translate("entities.employees"));
  }, [setDashboardTitle, translate]);

  return <EmployeesPageView />;
}

export default EmployeesPage;
