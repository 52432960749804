import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { userHasPermission } from "../helpers/Auth";

function PermissionGuard({ and = [], or = [], permission = null }) {
  const authContext = useAuthContext();
  const outletContext = useOutletContext();

  if (permission && !userHasPermission(authContext.auth.user, permission)) {
    return <Navigate to="/dashboard" replace />;
  }

  if (and.length > 0) {
    let failed = false;
    and.forEach((permission) => {
      if (!userHasPermission(authContext.auth.user, permission)) {
        failed = true;
      }
    });
    if (failed) {
      return <Navigate to="/dashboard" replace />;
    }
  }

  if (or.length > 0) {
    let failed = true;
    or.forEach((permission) => {
      if (userHasPermission(authContext.auth.user, permission)) {
        failed = false;
      }
    });
    if (failed) {
      return <Navigate to="/dashboard" replace />;
    }
  }

  return <Outlet context={outletContext} />;
}

export default PermissionGuard;
