import {
  faPen,
  faPlus,
  faSignInAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import EVAApi from "../../../apis/EVAApi";
import { useAuthContext } from "../../../context/AuthContext";
import { useLanguageContext } from "../../../context/LanguageContext";
import DataTable from "../DataTable";
import DataTableColumn from "../DataTableColumn";
import defaultDataTableFetch from "../DataTableFetch";
import DataTableHeaderAction from "../DataTableHeaderAction";
import DataTableRowAction from "../DataTableRowAction";

function CompaniesDataTable() {
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();
  const [data, setData] = useState(null);
  const [filtering, setFiltering] = useState({});

  /**
   * This callback will get the rows for this dataTable
   */
  const getData = useCallback(async (filtering) => {
    const newData = await defaultDataTableFetch("companies", filtering);
    setData(newData);
    setFiltering(filtering);
  }, []);

  /**
   * This method returns a list of datatable columns
   * @returns {object[]}
   */
  function getColumns() {
    return [
      DataTableColumn.create()
        .setName("name")
        .setLabel(translate("validation.attributes.name"))
        .setSelector((row) => row.name),
      DataTableColumn.create()
        .setName("contact")
        .setLabel(translate("validation.attributes.contact"))
        .setSelector((row) => row.contact),
      DataTableColumn.create()
        .setName("contact_details")
        .setLabel(translate("validation.attributes.contact_details"))
        .setSelector((row) => row.contact_details),
    ];
  }

  /**
   * This method returns a list of datatable row actions
   * @returns {object[]}
   */
  function getRowActions() {
    return [
      DataTableRowAction.create()
        .setIcon((row) => faSignInAlt)
        .setCallback(handleCompanyLogin),
      DataTableRowAction.create()
        .setIcon((row) => faPen)
        .setCallback(handleEdit),
      DataTableRowAction.create()
        .setIcon((row) => faTrash)
        .setCallback(handleDelete),
    ];
  }

  /**
   * This method returns a list of datatable header actions
   * @returns {object[]}
   */
  function getHeaderActions() {
    return [
      DataTableHeaderAction.create().setIcon(faPlus).setCallback(handleAdd),
    ];
  }

  /**
   * This method will try and login the current user to the given row company
   * @param {object} row
   */
  async function handleCompanyLogin(row) {
    authContext.loginToCompany(row.id);
  }

  /**
   * This method will try and add a new record
   */
  function handleAdd() {
    navigate("/dashboard/companies/create");
  }

  /**
   * This method will try and edit the given row
   * @param {object} row
   */
  function handleEdit(row) {
    navigate(`/dashboard/companies/${row.id}/edit`);
  }

  /**
   * This method will try and delete the given row
   * @param {object} row
   * @returns
   */
  async function handleDelete(row) {
    if (!window.confirm(translate("confirmations.delete.company"))) {
      return;
    }

    const promptAnswer = prompt(translate("confirmations.puzzle.date"));
    const currentDate = new Date();
    const correctAnswer = currentDate.getFullYear() + currentDate.getDate();
    if (parseInt(promptAnswer) !== correctAnswer) {
      return;
    }

    const response = await EVAApi.delete(`companies/${row.id}`);
    if (response.status === 200 && response.data?.success) {
      getData(filtering);
    }
  }

  /**
   * Return the base Datatable component
   */
  return (
    <DataTable
      data={data}
      getData={getData}
      columns={getColumns()}
      rowActions={getRowActions()}
      headerActions={getHeaderActions()}
    />
  );
}

export default CompaniesDataTable;
