import axios from "axios";
import moment from "moment";
import { Cookies } from "react-cookie";
import { EVA_API_HOST, EVA_API_VERSION } from "../config";

/**
 * The default axios configuration for fetching the EVA API
 */
const EVAApi = axios.create({
  baseURL: `${EVA_API_HOST}/${EVA_API_VERSION}/`,
});

/**
 * The request interceptor for the EVA API which will automatically set the authorization token
 */
export const AuthorizedRequestInterceptor = EVAApi.interceptors.request.use(
  (config) => {
    if (!config.headers["Authorization"]) {
      const token = new Cookies().get("jwt_token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * The request interceptor for the EVA API which will automatically set the Content-Language
 */
export const TranslatedRequestInterceptor = EVAApi.interceptors.request.use(
  (config) => {
    if (!config.headers["Content-Language"]) {
      const language = localStorage.getItem("language");
      if (language) {
        config.headers["Content-Language"] = language;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * This request interceptor will set the timezone offset for each API request
 */
export const TimezoneRequestInterceptor = EVAApi.interceptors.request.use(
  (config) => {
    if (!config.headers["Timezone-Offset"]) {
      config.headers["Timezone-Offset"] = moment().utcOffset();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * The response interceptor for the EVA API which will automatically logout the current user when the API returns a unautorized response
 */
export const UnauthorizedResponseInterceptor = EVAApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      if (window.location.pathname !== "/login") {
        window.location = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default EVAApi;
