import moment from "moment";
import { useTheme } from "styled-components";
import { useLanguageContext } from "../../context/LanguageContext";
import Card from "../card/Card";
import { StyledInput } from "../../styles/InputStyles";
import InputWrapper from "../inputWrapper/InputWrapper";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../styles/LayoutStyles";
import Select from "../select/Select";

/**
 * This method will added the data from the given schedule to the given formData
 *
 * @param {object} schedule
 * @param {FormData} formData
 */
export function appendScheduleToFromData(schedule, formData) {
  if (schedule?.frequency !== "none") {
    formData.append("schedule_frequency", schedule?.frequency);
  }
  if (schedule?.frequency !== "none" && schedule?.interval) {
    formData.append("schedule_interval", schedule?.interval);
  }
  if (schedule?.frequency !== "none" && schedule?.start_date) {
    formData.append("schedule_start_date", schedule?.start_date);
  }
  if (schedule?.frequency === "weekly" && schedule?.days) {
    schedule?.days.forEach((day, index) =>
      formData.append(`schedule_days[${index}]`, day)
    );
  }
  if (
    (schedule?.frequency === "monthly" || schedule?.frequency === "yearly") &&
    schedule.month_input_usage === "month_day" &&
    schedule?.month_day
  ) {
    formData.append("schedule_month_day", schedule?.month_day);
  }
  if (
    (schedule?.frequency === "monthly" || schedule?.frequency === "yearly") &&
    schedule.month_input_usage === "month_week" &&
    schedule?.month_week
  ) {
    formData.append("schedule_month_week", schedule?.month_week);
  }
  if (
    (schedule?.frequency === "monthly" || schedule?.frequency === "yearly") &&
    schedule.month_input_usage === "month_week" &&
    schedule?.month_day_of_week
  ) {
    formData.append("schedule_month_day_of_week", schedule?.month_day_of_week);
  }
  if (schedule?.frequency === "yearly" && schedule?.year_month) {
    formData.append("schedule_year_month", schedule?.year_month);
  }
}

/**
 * This method will make sure that the given schedule has the correct data in it.
 * This is important for append the data to the forms and for using the SchedulingForm.
 *
 * @param {object} schedule
 * @returns {object}
 */
export function correctScheduleInput(schedule) {
  const correctedSchedule = {
    frequency: schedule?.frequency ?? "none",
    interval: schedule?.interval ?? 1,
    start_date: schedule?.start_date ?? moment().format("YYYY-MM-DD"),
    days: schedule?.days ?? ["monday"],
    month_day: schedule?.month_day ?? 1,
    month_week: schedule?.month_week ?? "first",
    month_day_of_week: schedule?.month_day_of_week ?? "monday",
    year_month: schedule?.year_month ?? moment().format("MMMM").toLowerCase(),
    month_input_usage:
      schedule?.month_input_usage ??
      (schedule?.month_day_of_week ? "month_week" : "month_day"),
  };

  return correctedSchedule;
}

function SchedulingForm({ schedule, onScheduleChange, margin }) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  /**
   * This method will return all options for the frequency select
   * @returns {object[]}
   */
  function getFrequencySelectOptions() {
    return [
      {
        label: translate("main.select"),
        value: "none",
      },
      {
        label: translate("calendar.frequencies.daily"),
        value: "daily",
      },
      {
        label: translate("calendar.frequencies.weekly"),
        value: "weekly",
      },
      {
        label: translate("calendar.frequencies.monthly"),
        value: "monthly",
      },
      {
        label: translate("calendar.frequencies.yearly"),
        value: "yearly",
      },
    ];
  }

  /**
   * This method will get the option which should be selected in the frequency select
   * @returns {object}
   */
  function getFrequencySelectValue() {
    return getFrequencySelectOptions().find(
      (option) => option.value === schedule?.frequency
    );
  }

  /**
   * This method will handle the functionality if the value of the frequency select has changed
   * @param {object} option
   */
  function handleFrequencySelectChange(option) {
    onScheduleChange({ ...schedule, frequency: option.value });
  }

  /**
   * This method will handle the functionality if the value of the interval input has changed
   * @param {event} e
   */
  function handleIntervalInputChange(e) {
    onScheduleChange({
      ...schedule,
      interval: Math.max(e.target.value, 1),
    });
  }

  /**
   * This method will return the correct hint which will be used for the interval input
   * @returns {string}
   */
  function getIntervalHint() {
    if (!schedule) {
      return "";
    }

    switch (schedule?.frequency) {
      case "daily":
        return translate("calendar.days.all");
      case "weekly":
        return translate("calendar.weeks.all");
      case "monthly":
        return translate("calendar.months.all");
      case "yearly":
        return translate("calendar.years");
      default:
        return "";
    }
  }

  /**
   * This method will handle the functionality for when the start date input has changed
   * @param {event} e
   */
  function handleStartDateInputChange(e) {
    onScheduleChange({ ...schedule, start_date: e.target.value });
  }

  /**
   * This method will return all options for the days select
   * @returns {object[]}
   */
  function getDaysSelectOptions() {
    moment.locale("en");
    return moment.weekdays().map((day) => {
      return {
        label: translate("calendar.days." + day.toLowerCase()),
        value: day.toLowerCase(),
      };
    });
  }

  /**
   * This method will return the options that should be selected in the days select
   * @returns {object}
   */
  function getDaysSelectValue() {
    return getDaysSelectOptions().filter((option) =>
      schedule?.days.find((day) => option.value === day)
    );
  }

  /**
   * This method will handle the onchange event for the days select
   * @param {object[]} options
   */
  function handleDaysSelectChange(options) {
    onScheduleChange({
      ...schedule,
      days: options.map((option) => option.value),
    });
  }

  /**
   * This method will handle the onchange event for the month_day input
   * @param {event} e
   */
  function handleMonthDayInputChange(e) {
    onScheduleChange({
      ...schedule,
      month_day: Math.min(Math.max(e.target.value, 1), 31),
    });
  }

  /**
   * This method will return all options which are used for the month_week select
   * @returns {object[]}
   */
  function getMonthWeekSelectOptions() {
    return [
      {
        label: translate("calendar.weeks.first"),
        value: "first",
      },
      {
        label: translate("calendar.weeks.second"),
        value: "second",
      },
      {
        label: translate("calendar.weeks.third"),
        value: "third",
      },
      {
        label: translate("calendar.weeks.fourth"),
        value: "fourth",
      },
      {
        label: translate("calendar.weeks.last"),
        value: "last",
      },
    ];
  }

  /**
   * This method will determine which option in the month_week select should be selected
   * @returns {object}
   */
  function getMonthWeekSelectValue() {
    return getMonthWeekSelectOptions().find(
      (option) => option.value === schedule?.month_week
    );
  }

  /**
   * This method will handle the onchange event for the month_week select
   * @param {object} option
   */
  function handleMonthWeekSelectChange(option) {
    onScheduleChange({ ...schedule, month_week: option.value });
  }

  /**
   * This method will return the option of the month_day_of_week select which should be selected
   * @returns {object}
   */
  function getMonthDayOfWeekSelectValue() {
    return getDaysSelectOptions().find(
      (option) => option.value === schedule?.month_day_of_week
    );
  }

  /**
   * This method will handle the onchange event of the month_day_of_week input
   * @param {object} option
   */
  function handleMonthDayOfWeekSelectChange(option) {
    onScheduleChange({ ...schedule, month_day_of_week: option.value });
  }

  /**
   * This method will return all year month select options
   * @returns {object[]}
   */
  function getYearMonthSelectOptions() {
    moment.locale("en");
    return moment.months().map((month) => {
      return {
        label: translate("calendar.months." + month.toLowerCase()),
        value: month.toLowerCase(),
      };
    });
  }

  /**
   * This method will return the option of the year_month select which should be selected
   * @returns {object}
   */
  function getYearMonthSelectValue() {
    return getYearMonthSelectOptions().find(
      (option) => option.value === schedule?.year_month
    );
  }

  /**
   * This method will handle the onchange event of the year_month select
   * @param {object} option
   */
  function handleYearMonthSelectChange(option) {
    onScheduleChange({ ...schedule, year_month: option.value });
  }

  /**
   * This method will handle the onchange event of the month_input_usage  input.
   * @param {string} value
   */
  function handleMonthInputUsageChange(value) {
    onScheduleChange({ ...schedule, month_input_usage: value });
  }

  return (
    <Card header={translate("calendar.schedule")} margin={margin}>
      <StyledFlexLayout margin={`0px 0px ${theme.spacing.normal}`}>
        <StyledFlexLayoutItem>
          <InputWrapper
            label={translate("validation.attributes.schedule_frequency")}
          >
            <Select
              options={getFrequencySelectOptions()}
              value={getFrequencySelectValue()}
              onChange={handleFrequencySelectChange}
            />
          </InputWrapper>
        </StyledFlexLayoutItem>

        {schedule?.frequency !== "none" ? (
          <InputWrapper
            label={
              translate("validation.attributes.schedule_interval") +
              " (" +
              getIntervalHint() +
              ")"
            }
          >
            <StyledInput
              type="number"
              value={schedule?.interval}
              onChange={handleIntervalInputChange}
            />
          </InputWrapper>
        ) : (
          ""
        )}
      </StyledFlexLayout>

      {schedule?.frequency !== "none" ? (
        <>
          <InputWrapper
            label={translate("validation.attributes.schedule_start_date")}
            margin={`0px 0px ${theme.spacing.normal}`}
          >
            <StyledInput
              type="date"
              value={schedule?.start_date}
              onChange={handleStartDateInputChange}
            />
          </InputWrapper>

          {schedule?.frequency === "weekly" ? (
            <InputWrapper
              label={translate("validation.attributes.schedule_days")}
            >
              <Select
                isMulti={true}
                options={getDaysSelectOptions()}
                value={getDaysSelectValue()}
                onChange={handleDaysSelectChange}
              />
            </InputWrapper>
          ) : (
            ""
          )}

          {schedule?.frequency === "monthly" ||
          schedule?.frequency === "yearly" ? (
            <>
              <StyledFlexLayout margin={`0px 0px ${theme.spacing.normal}`}>
                <input
                  type="radio"
                  name="month_input_usage"
                  value="month_day"
                  checked={schedule?.month_input_usage === "month_day"}
                  onClick={() => handleMonthInputUsageChange("month_day")}
                  onChange={() => {}}
                />

                <StyledFlexLayoutItem>
                  <InputWrapper
                    label={translate(
                      "validation.attributes.schedule_month_day"
                    )}
                  >
                    <StyledInput
                      type="number"
                      value={schedule?.month_day}
                      onChange={handleMonthDayInputChange}
                      disabled={schedule?.month_input_usage !== "month_day"}
                    />
                  </InputWrapper>
                </StyledFlexLayoutItem>
              </StyledFlexLayout>

              <StyledFlexLayout margin={`0px 0px ${theme.spacing.normal}`}>
                <input
                  type="radio"
                  name="month_input_usage"
                  value="month_week"
                  checked={schedule?.month_input_usage === "month_week"}
                  onClick={() => handleMonthInputUsageChange("month_week")}
                  onChange={() => {}}
                />

                <InputWrapper
                  label={translate("validation.attributes.schedule_month_week")}
                >
                  <Select
                    options={getMonthWeekSelectOptions()}
                    value={getMonthWeekSelectValue()}
                    onChange={handleMonthWeekSelectChange}
                    disabled={schedule?.month_input_usage !== "month_week"}
                  />
                </InputWrapper>

                <StyledFlexLayoutItem>
                  <InputWrapper
                    label={translate(
                      "validation.attributes.schedule_month_day_of_week"
                    )}
                  >
                    <Select
                      options={getDaysSelectOptions()}
                      value={getMonthDayOfWeekSelectValue()}
                      onChange={handleMonthDayOfWeekSelectChange}
                      disabled={schedule?.month_input_usage !== "month_week"}
                    />
                  </InputWrapper>
                </StyledFlexLayoutItem>
              </StyledFlexLayout>
            </>
          ) : (
            ""
          )}

          {schedule?.frequency === "yearly" ? (
            <InputWrapper
              label={translate("validation.attributes.schedule_year_month")}
            >
              <Select
                options={getYearMonthSelectOptions()}
                value={getYearMonthSelectValue()}
                onChange={handleYearMonthSelectChange}
              />
            </InputWrapper>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </Card>
  );
}

export default SchedulingForm;
