import styled from "styled-components";

export const StyledFlexLayout = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  gap: ${(props) => (props.gap ? props.gap : props.theme.spacing.normal)};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
`;

export const StyledFlexLayoutItem = styled.div`
  flex: ${(props) => (props.flex ? props.flex : "1")};
`;
