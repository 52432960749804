import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import EVAApi from "../../../../apis/EVAApi";
import { useLanguageContext } from "../../../../context/LanguageContext";
import LanguagePageView from "./LanguagePageView";

function LanguagePage() {
  const LANGUAGES_PAGE_URL = "/dashboard/languages";

  const navigate = useNavigate();
  const { language } = useParams();
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();

  const [languageData, setLanguageData] = useState({
    fetched: false,
    code: "",
    label: "",
    default: false,
  });
  const [submitErrors, setSubmitErrors] = useState(null);

  /**
   * This method will fetch the initial data from the API
   */
  const fetchLanguageData = useCallback(async () => {
    try {
      const response = await EVAApi.get(`languages/${language}`);
      if (response.status === 200 && response.data?.data) {
        setLanguageData({
          fetched: true,
          code: response.data.data.code,
          label: response.data.data.label,
          default: response.data.data.default,
        });
      }
    } catch (error) {
      navigate(LANGUAGES_PAGE_URL);
    }
  }, [language, navigate]);

  /**
   * This effect will initialize the page
   */
  useEffect(() => {
    setDashboardTitle(
      language
        ? translate("entities.actions.edit", [
            { key: "entities", value: translate("entities.language") },
          ])
        : translate("entities.actions.create", [
            { key: "entities", value: translate("entities.language") },
          ])
    );

    if (language) {
      fetchLanguageData();
    }
  }, [language, setDashboardTitle, fetchLanguageData, translate]);

  /**
   * This method will update the code on input change
   * @param {event} e
   */
  function onCodeChange(e) {
    setLanguageData({ ...languageData, code: e.target.value });
  }

  /**
   * This method will update the label on input change
   * @param {event} e
   */
  function onLabelChange(e) {
    setLanguageData({ ...languageData, label: e.target.value });
  }

  /**
   * This method will create and return the FormData object for submission
   * @returns {FormData}
   */
  function getSubmitData() {
    const formData = new FormData();
    formData.append("code", languageData.code);
    formData.append("label", languageData.label);
    if (language) {
      formData.append("_method", "PUT");
    }
    return formData;
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    setSubmitErrors(null);

    try {
      const response = await EVAApi.request({
        url: language ? `languages/${language}` : "languages",
        method: "POST",
        data: getSubmitData(),
      });

      if (response.status === 200 && response.data?.success) {
        navigate(LANGUAGES_PAGE_URL);
      }
    } catch (error) {
      if (error.response?.data) {
        setSubmitErrors(error.response.data);
      }
    }
  }

  return (
    <LanguagePageView
      language={language}
      languageData={languageData}
      onCodeChange={onCodeChange}
      onLabelChange={onLabelChange}
      onSubmit={onSubmit}
      submitErrors={submitErrors}
    />
  );
}

export default LanguagePage;
