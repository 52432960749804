import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import EVAApi from "../../../../../apis/EVAApi";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import {
  appendScheduleToFromData,
  correctScheduleInput,
} from "../../../../../components/schedulingForm/SchedulingForm";
import DishPageView from "./DishPageView";

function DishPage() {
  const DISHES_PAGE_URL = "/dashboard/canteen/dishes";

  const navigate = useNavigate();
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();
  const { dish } = useParams();

  const [allergens, setAllergens] = useState({ fetched: false, records: [] });
  const [dishData, setDishData] = useState({
    fetched: false,
    name: "",
    description: "",
    price: 0.0,
    capacity: 1,
    calories: 0,
    health_score: 0,
    category: "",
    image: null,
    allergens: [],
    schedule: null,
  });
  const [submitErrors, setSubmitErrors] = useState(null);

  /**
   * Fetch the allergens from the API
   */
  const fetchAllergens = useCallback(async () => {
    try {
      const response = await EVAApi.get("canteen/allergens");
      if (response.status === 200 && response.data?.data) {
        setAllergens({ fetched: true, records: response.data.data.records });
      }
    } catch (error) {
      navigate(DISHES_PAGE_URL);
    }
  }, [navigate]);

  /**
   * Fetch the dish data from the API
   */
  const fetchDishData = useCallback(async () => {
    try {
      const response = await EVAApi.get(`canteen/dishes/${dish}`);
      if (response.status === 200 && response.data?.data) {
        setDishData({
          fetched: true,
          name: response.data.data.name,
          description: response.data.data.description,
          price: response.data.data.price,
          capacity: response.data.data.capacity,
          calories: response.data.data.calories,
          health_score: response.data.data.health_score,
          category: response.data.data.category ?? "",
          image: response.data.data.image,
          allergens: response.data.data.allergens,
          schedule: response.data.data.schedule,
        });
      }
    } catch (error) {
      navigate(DISHES_PAGE_URL);
    }
  }, [dish, navigate]);

  /**
   * Initializes the component
   */
  useEffect(() => {
    setDashboardTitle(
      dish
        ? translate("entities.actions.edit", [
            { key: "entities", value: translate("entities.dish") },
          ])
        : translate("entities.actions.create", [
            { key: "entities", value: translate("entities.dish") },
          ])
    );

    fetchAllergens();
    if (dish) {
      fetchDishData();
    }
  }, [translate, setDashboardTitle, dish, fetchDishData, fetchAllergens]);

  /**
   * This method will update the image on input change
   * @param {event} e
   */
  function onImageChange(e) {
    setDishData({ ...dishData, image: e.target.files[0] });
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setDishData({ ...dishData, name: e.target.value });
  }

  /**
   * This method will update the price on input change
   * @param {event} e
   */
  function onPriceChange(e) {
    setDishData({ ...dishData, price: e.target.value });
  }

  /**
   * This method will update the capacity on input change
   * @param {event} e
   */
  function onCapacityChange(e) {
    setDishData({ ...dishData, capacity: e.target.value });
  }

  /**
   * This method will update the calories on input change
   * @param {event} e
   */
  function onCaloriesChange(e) {
    setDishData({ ...dishData, calories: e.target.value });
  }

  /**
   * This method will update the health score on input change
   * @param {event} e
   */
  function onHealthScoreChange(e) {
    setDishData({ ...dishData, health_score: e.target.value });
  }

  /**
   * This method will update the category on input change
   * @param {event} e
   */
  function onCategoryChange(e) {
    setDishData({ ...dishData, category: e.target.value });
  }

  /**
   * This method will update the description on input change
   * @param {event} e
   */
  function onDescriptionChange(e) {
    setDishData({ ...dishData, description: e.target.value });
  }

  /**
   * This method will update the allergens on input change
   * @param {object[]} options
   */
  function onAllergensChange(options) {
    setDishData({
      ...dishData,
      allergens: options.map((option) => option.value),
    });
  }

  /**
   * This method will update the schedule on input change
   * @param {object} schedule
   */
  function onScheduleChange(schedule) {
    setDishData({ ...dishData, schedule: schedule });
  }

  /**
   * This method will create and return the FormData object for submission
   * @returns {FormData}
   */
  function getSubmitData() {
    const formData = new FormData();
    formData.append("name", dishData.name);
    formData.append("description", dishData.description ?? "");
    formData.append("price", dishData.price);
    formData.append("capacity", dishData.capacity);
    formData.append("calories", dishData.calories);
    formData.append("health_score", dishData.health_score);
    formData.append("category", dishData.category);
    dishData.allergens.forEach((allergen, index) => {
      formData.append(`allergens[${index}]`, allergen);
    });
    if (dishData.image instanceof File) {
      formData.append("image", dishData.image);
    }
    appendScheduleToFromData(correctScheduleInput(dishData.schedule), formData);
    if (dish) {
      formData.append("_method", "PUT");
    }

    return formData;
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    setSubmitErrors(null);

    try {
      const response = await EVAApi.request({
        url: dish ? `canteen/dishes/${dish}` : "canteen/dishes",
        method: "POST",
        data: getSubmitData(),
      });

      if (response.status === 200 && response.data?.success) {
        navigate(DISHES_PAGE_URL);
      }
    } catch (error) {
      if (error.response?.data) {
        setSubmitErrors(error.response.data);
      }
    }
  }

  return (
    <DishPageView
      dish={dish}
      dishData={dishData}
      allergens={allergens}
      onImageChange={onImageChange}
      onNameChange={onNameChange}
      onPriceChange={onPriceChange}
      onCapacityChange={onCapacityChange}
      onCaloriesChange={onCaloriesChange}
      onHealthScoreChange={onHealthScoreChange}
      onCategoryChange={onCategoryChange}
      onDescriptionChange={onDescriptionChange}
      onAllergensChange={onAllergensChange}
      onScheduleChange={onScheduleChange}
      onSubmit={onSubmit}
      submitErrors={submitErrors}
    />
  );
}

export default DishPage;
