import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import {
  StyledDashboardNavButton,
  StyledDashboardNavButtonLabel,
} from "./DashboardNavButtonStyles";

function DashboardNavButton({
  icon,
  label,
  pathname,
  folded,
  onlyActiveOnSelf = false,
  excludeFromPath = [],
  show = true,
}) {
  const location = useLocation();

  function isActive() {
    let active = location.pathname.startsWith(pathname);

    if (onlyActiveOnSelf) {
      active = location.pathname.endsWith(pathname);
    } else {
      excludeFromPath.forEach((exclude) => {
        active = location.pathname.includes(exclude) ? false : active;
      });
    }

    return active;
  }

  return show ? (
    <Link to={pathname}>
      <StyledDashboardNavButton active={isActive()}>
        <FontAwesomeIcon icon={icon} />
        <StyledDashboardNavButtonLabel hide={folded}>
          {label}
        </StyledDashboardNavButtonLabel>
      </StyledDashboardNavButton>
    </Link>
  ) : (
    ""
  );
}

export default DashboardNavButton;
