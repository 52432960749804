import styled from "styled-components";

export const StyledLoginWidthConstraint = styled.div`
  max-width: 28rem;
  margin: 0px auto;
  padding: 0px ${(props) => props.theme.spacing.normal};
`;

export const StyledLoginLogo = styled.img`
  max-width: 50%;
  margin: ${(props) => props.theme.spacing.large} auto
    ${(props) => props.theme.spacing.large} auto;
`;

export const StyledLoginAnnouncementsOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  pointer-events: ${(props) => (props.active ? "initial" : "none")};
  opacity: ${(props) => (props.active ? "1" : "0")};
  transition: all ${(props) => props.theme.transition.duration.default};
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: ${(props) => props.theme.spacing.normal} 0px
    ${(props) => props.theme.spacing.large} 0px;
  color: white;
`;

export const StyledLoginAnnouncementsHeader = styled.header`
  margin-bottom: ${(props) => props.theme.spacing.large};
`;

export const StyledLoginAnnouncementContainer = styled.div`
  padding: ${(props) => props.theme.spacing.normal};
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
`;
