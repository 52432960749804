import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import RolesPageView from "./RolesPageView";

function RolesPage() {
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();

  useEffect(() => {
    setDashboardTitle(translate("entities.roles"));
  }, [setDashboardTitle, translate]);

  return <RolesPageView />;
}

export default RolesPage;
