import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../../context/LanguageContext";
import Card from "../../../../components/card/Card";
import InputWrapper from "../../../../components/inputWrapper/InputWrapper";
import { StyledInput } from "../../../../styles/InputStyles";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../styles/LayoutStyles";
import Select from "../../../../components/select/Select";
import { StyledButton } from "../../../../styles/ButtonStyles";
import { useAuthContext } from "../../../../context/AuthContext";

function UserPageView({
  user,
  userData,
  userUpdateSelfOnly,
  roles,
  employees,
  onNameChange,
  onEmailChange,
  onPasswordChange,
  onEmployeeIDChange,
  onRolesChange,
  onSubmit,
  submitErrors,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();
  const authContext = useAuthContext();

  //Define employee input options
  const employeeInputOptions = employees.records.map((employee) => {
    return { value: employee.id, label: employee.name };
  });
  employeeInputOptions.unshift({ value: "", label: translate("main.select") });

  //Define roles input options
  const rolesInputOptions = roles.records.map((role) => {
    return { value: role.id, label: role.name };
  });

  return (authContext.auth.user.company.type === "superadmin" ||
    (roles.fetched && employees.fetched)) &&
    (!user || userData.fetched) ? (
    <Card header={translate("main.data")}>
      <InputWrapper
        label={translate("validation.attributes.name")}
        error={submitErrors?.errors?.name?.[0]}
        required
      >
        <StyledInput
          type="text"
          placeholder={translate("validation.placeholders.name.person")}
          value={userData.name}
          onChange={onNameChange}
          disabled={userUpdateSelfOnly}
        />
      </InputWrapper>

      <StyledFlexLayout margin={`${theme.spacing.normal} 0px`}>
        <StyledFlexLayoutItem>
          <InputWrapper
            label={translate("validation.attributes.email")}
            error={submitErrors?.errors?.email?.[0]}
            required
          >
            <StyledInput
              type="email"
              placeholder={translate("validation.placeholders.email")}
              value={userData.email}
              onChange={onEmailChange}
              disabled={userUpdateSelfOnly}
            />
          </InputWrapper>
        </StyledFlexLayoutItem>

        <StyledFlexLayoutItem>
          <InputWrapper
            label={translate("validation.attributes.password")}
            error={submitErrors?.errors?.password?.[0]}
            required={!user}
          >
            <StyledInput
              type="password"
              placeholder={translate("validation.placeholders.password")}
              value={userData.password}
              onChange={onPasswordChange}
            />
          </InputWrapper>
        </StyledFlexLayoutItem>
      </StyledFlexLayout>

      {authContext.auth.user.company.type !== "superadmin" ? (
        <>
          <InputWrapper label={translate("entities.employee")}>
            <Select
              options={employeeInputOptions}
              value={employeeInputOptions.find(
                (option) => option.value === userData.employeeID
              )}
              onChange={onEmployeeIDChange}
              disabled={userUpdateSelfOnly}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("entities.roles")}
            margin={`${theme.spacing.normal} 0px`}
          >
            <Select
              isMulti
              options={rolesInputOptions}
              value={rolesInputOptions.filter((option) =>
                userData.roles.find((userRole) => userRole === option.value)
              )}
              onChange={onRolesChange}
              disabled={userUpdateSelfOnly}
            />
          </InputWrapper>
        </>
      ) : (
        ""
      )}

      <StyledButton
        onClick={onSubmit}
        margin={`${theme.spacing.normal} 0px 0px 0px`}
      >
        {translate("main.save")}
      </StyledButton>
    </Card>
  ) : (
    ""
  );
}

export default UserPageView;
