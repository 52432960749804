import styled from "styled-components";

export const StyledOrderPageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const StyledOrderPreviewContainer = styled.div`
  position: absolute;
  display: flex;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: center;
  bottom: 0;
  right: 0;
  border-radius: ${(props) => props.theme.radius.default};
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  box-shadow: ${(props) => props.theme.shadow.default};
  padding: ${(props) => props.theme.spacing.normal};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: all ${(props) => props.theme.transition.duration.default};
  margin-right: ${(props) => props.theme.spacing.large};
  margin-bottom: ${(props) => props.theme.spacing.large};
  transform: ${(props) => (props.show ? "translateX(0%)" : "translateX(100%)")};
`;

export const StyledDishDisplayImage = styled.img`
  width: 100px;
  height: 100px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const StyledDishDisplayPrice = styled.small`
  background-color: ${(props) => props.theme.colors.accent.normal};
  color: ${(props) => props.theme.colors.on.accent};
  border-radius: ${(props) => props.theme.radius.small};
  padding-inline: ${(props) => props.theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledOrderTable = styled.table`
  width: 100%;
`;

export const StyledOrderTableRow = styled.tr`
  border-block: 1px solid rgba(255, 255, 255, 0.1);
`;

export const StyledOrderTableTd = styled.td`
  height: 45px;
  padding-inline: ${(props) => props.theme.spacing.normal};
`;

export const StyledOrderTableNameTd = styled(StyledOrderTableTd)`
  width: 100%;
`;
