import { useCallback, useState } from "react";
import { useLanguageContext } from "../../../../context/LanguageContext";
import DataTable from "../../DataTable";
import DataTableColumn from "../../DataTableColumn";
import defaultDataTableFetch from "../../DataTableFetch";

function OrdersLogDataTable() {
  const { translate } = useLanguageContext();

  const [data, setData] = useState(null);

  /**
   * This callback will get the rows for this dataTable
   */
  const getData = useCallback(async (filtering) => {
    const newData = await defaultDataTableFetch("canteen/orders/log", {
      ...filtering,
    });
    setData(newData);
  }, []);

  /**
   * This method returns a list of datatable columns
   * @returns {object[]}
   */
  function getColumns() {
    return [
      DataTableColumn.create()
        .setName("employee")
        .setLabel(translate("validation.attributes.employee_id"))
        .setSelector((row) => row.employee_name),
      DataTableColumn.create()
        .setName("price")
        .setLabel(translate("validation.attributes.price"))
        .setSelector((row) => row.price),
      DataTableColumn.create()
        .setName("dishes")
        .setLabel(translate("validation.attributes.dishes"))
        .setSelector((row) =>
          row.dishes.map(
            (dish, index) => dish + (index < row.dishes.length - 1 ? ", " : "")
          )
        ),
      DataTableColumn.create()
        .setName("created_at")
        .setLabel(translate("validation.attributes.created_at"))
        .setSelector((row) => row.created_at),
    ];
  }

  /**
   * This method returns a list of datatable row actions
   * @returns {object[]}
   */
  function getRowActions() {
    return [];
  }

  /**
   * This method returns a list of datatable header actions
   * @returns {object[]}
   */
  function getHeaderActions() {
    return [];
  }

  /**
   * Return the base Datatable component
   */
  return (
    <DataTable
      data={data}
      getData={getData}
      columns={getColumns()}
      rowActions={getRowActions()}
      headerActions={getHeaderActions()}
      defaultOrderBy="created_at"
    />
  );
}

export default OrdersLogDataTable;
