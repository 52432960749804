import {
  StyledDashboardNavButtonGroup,
  StyledDashboardNavButtonGroupLabel,
} from "./DashboardNavButtonGroupStyles";

function DashboardNavButtonGroup({ label, navFolded, children }) {
  const show = children.filter((child) => child.props.show === true).length > 0;

  return show ? (
    <StyledDashboardNavButtonGroup>
      <StyledDashboardNavButtonGroupLabel hide={navFolded}>
        {label}
      </StyledDashboardNavButtonGroupLabel>
      {children}
    </StyledDashboardNavButtonGroup>
  ) : (
    ""
  );
}

export default DashboardNavButtonGroup;
