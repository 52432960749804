import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";

function NotFoundPage() {
  const authContext = useAuthContext();

  return (
    <Navigate to={authContext.auth.token ? "/dashboard" : "/login"} replace />
  );
}

export default NotFoundPage;
