import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import DishesPageView from "./DishesPageView";

function DishesPage() {
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();

  useEffect(() => {
    setDashboardTitle(translate("entities.dishes"));
  }, [setDashboardTitle, translate]);

  return <DishesPageView />;
}

export default DishesPage;
