import { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../../../context/AuthContext";
import EVAApi from "../../../apis/EVAApi";
import LoginPageView from "./LoginPageView";

function LoginPage() {
  const authContext = useAuthContext();

  const [showAnnouncements, setShowAnnouncements] = useState(false);
  const [announcements, setAnnouncements] = useState({
    fetched: false,
    records: [],
  });
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [submitErrors, setSubmitErrors] = useState(null);

  /**
   * This callback will get the active announcements from the API
   */
  const fetchAnnouncements = useCallback(async () => {
    try {
      const response = await EVAApi.get("announcements", {
        params: { active_only: true },
      });
      if (response.status === 200 && response.data?.data) {
        setAnnouncements({
          fetched: true,
          records: response.data.data.records,
        });
      }
    } catch (error) {
      if (
        error.response?.status === 404 &&
        error.response?.data?.success === true
      ) {
        setAnnouncements({ fetched: true, records: [] });
      }
    }
  }, []);

  /**
   * This effect will initialize the component
   */
  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  /**
   * This method will update the email on input change
   * @param {event} e
   */
  function onEmailChange(e) {
    setLoginData({ ...loginData, email: e.target.value });
  }

  /**
   * This method will update the email on input change
   * @param {event} e
   */
  function onPasswordChange(e) {
    setLoginData({ ...loginData, password: e.target.value });
  }

  /**
   * This method will submit the form to log the user in
   */
  async function onSubmit() {
    setSubmitErrors(null);

    const response = await authContext.login(
      loginData.email,
      loginData.password
    );

    if (response.status !== 200 && response?.data) {
      setSubmitErrors(response.data);
    }
  }

  return (
    <LoginPageView
      loginData={loginData}
      announcements={announcements}
      onEmailChange={onEmailChange}
      onPasswordChange={onPasswordChange}
      onSubmit={onSubmit}
      submitErrors={submitErrors}
      showAnnouncements={showAnnouncements}
      setShowAnnouncements={setShowAnnouncements}
    />
  );
}

export default LoginPage;
