import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import EVAApi from "../../../../apis/EVAApi";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { getPublicEVAImage } from "../../../../helpers/EVAFileManager";
import DataTable from "../../DataTable";
import DataTableColumn from "../../DataTableColumn";
import defaultDataTableFetch from "../../DataTableFetch";
import DataTableHeaderAction from "../../DataTableHeaderAction";
import DataTableRowAction from "../../DataTableRowAction";
import { useAuthContext } from "../../../../context/AuthContext";
import { userHasPermission } from "../../../../helpers/Auth";

function DishesDataTable() {
  const theme = useTheme();
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  const [data, setData] = useState(null);
  const [filtering, setFiltering] = useState({});

  /**
   * This callback will get the rows for this dataTable
   */
  const getData = useCallback(async (filtering) => {
    const newData = await defaultDataTableFetch("canteen/dishes", filtering);
    setData(newData);
    setFiltering(filtering);
  }, []);

  /**
   * This method returns a list of datatable columns
   * @returns {object[]}
   */
  function getColumns() {
    return [
      DataTableColumn.create()
        .setName("dishes.image")
        .setSelector((row) => (
          <img
            src={getPublicEVAImage(row.image)}
            alt={translate("validation.attributes.image")}
            style={{ width: "35px", height: "35px" }}
          />
        ))
        .setHeaderStyling({
          width: `calc(35px + ${theme.spacing.normal} * 2)`,
        })
        .setOrderable(false)
        .setSearchable(false),
      DataTableColumn.create()
        .setName("dishes.name")
        .setLabel(translate("validation.attributes.name"))
        .setSelector((row) => row.name),
      DataTableColumn.create()
        .setName("dishes.price")
        .setLabel(translate("validation.attributes.price"))
        .setSelector((row) => row.price)
        .setHeaderStyling({ width: "0px" }),
      DataTableColumn.create()
        .setName("dishes.capacity")
        .setLabel(translate("validation.attributes.capacity"))
        .setSelector((row) => row.capacity)
        .setHeaderStyling({ width: "0px" }),
      DataTableColumn.create()
        .setName("dishes.calories")
        .setLabel(translate("validation.attributes.calories"))
        .setSelector((row) => row.calories)
        .setHeaderStyling({ width: "0px" }),
      DataTableColumn.create()
        .setName("dishes.health_score")
        .setLabel(translate("validation.attributes.health_score"))
        .setSelector((row) => row.health_score)
        .setHeaderStyling({ width: "0px" }),
      DataTableColumn.create()
        .setName("dishes.category")
        .setLabel(translate("validation.attributes.category"))
        .setSelector((row) => row.category)
        .setHeaderStyling({ width: "0px" }),
      DataTableColumn.create()
        .setName("dishes.schedule_frequency")
        .setLabel(translate("calendar.schedule"))
        .setSelector((row) =>
          row.schedule
            ? translate("calendar.frequencies." + row.schedule?.frequency)
            : ""
        )
        .setHeaderStyling({ width: "0px" })
        .setOrderable(false)
        .setSearchable(false),
    ];
  }

  /**
   * This method returns a list of datatable row actions
   * @returns {object[]}
   */
  function getRowActions() {
    return [
      DataTableRowAction.create()
        .setIcon((row) => faPen)
        .setCallback(handleEdit)
        .setDisableOn(
          (row) =>
            !userHasPermission(authContext.auth.user, "canteen.dishes.update")
        ),
      DataTableRowAction.create()
        .setIcon((row) => faTrash)
        .setCallback(handleDelete)
        .setDisableOn(
          (row) =>
            !userHasPermission(authContext.auth.user, "canteen.dishes.delete")
        ),
    ];
  }

  /**
   * This method returns a list of datatable header actions
   * @returns {object[]}
   */
  function getHeaderActions() {
    return [
      DataTableHeaderAction.create()
        .setIcon(faPlus)
        .setCallback(handleAdd)
        .setDisabled(
          !userHasPermission(authContext.auth.user, "canteen.dishes.create")
        ),
    ];
  }

  /**
   * This method will try and add a new record
   */
  function handleAdd() {
    navigate("/dashboard/canteen/dishes/create");
  }

  /**
   * This method will try and edit the given row
   * @param {object} row
   */
  function handleEdit(row) {
    navigate(`/dashboard/canteen/dishes/${row.id}/edit`);
  }

  /**
   * This method will try and delete the given row
   * @param {object} row
   * @returns
   */
  async function handleDelete(row) {
    if (!window.confirm(translate("confirmations.delete.dish"))) {
      return;
    }

    const response = await EVAApi.delete(`canteen/dishes/${row.id}`);
    if (response.status === 200 && response.data?.success) {
      getData(filtering);
    }
  }

  /**
   * Return the base Datatable component
   */
  return (
    <DataTable
      data={data}
      getData={getData}
      columns={getColumns()}
      rowActions={getRowActions()}
      headerActions={getHeaderActions()}
      defaultOrderBy={"dishes.name"}
      defaultSort="asc"
      paginationOptions={["10", "25", "50", "100", "500"]}
      paginationOption={"500"}
    />
  );
}

export default DishesDataTable;
