import { Route, Routes } from "react-router-dom";
import { useAuthContext } from "./context/AuthContext";
import { useLanguageContext } from "./context/LanguageContext";
import LoginPage from "./pages/auth/login/LoginPage";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import DashboardPage from "./pages/dashboard/index/DashboardPage";
import UsersPage from "./pages/dashboard/users/index/UsersPage";
import UserPage from "./pages/dashboard/users/user/UserPage";
import RolesPage from "./pages/dashboard/users/roles/index/RolesPage";
import RolePage from "./pages/dashboard/users/roles/role/RolePage";
import CompaniesPage from "./pages/dashboard/companies/index/CompaniesPage";
import CompanyPage from "./pages/dashboard/companies/company/CompanyPage";
import AnnouncementsPage from "./pages/dashboard/announcements/index/AnnouncementsPage";
import AnnouncementPage from "./pages/dashboard/announcements/announcement/AnnouncementPage";
import LanguagesPage from "./pages/dashboard/languages/index/LanguagesPage";
import LanguagePage from "./pages/dashboard/languages/language/LanguagePage";
import EmployeesPage from "./pages/dashboard/employees/index/EmployeesPage";
import EmployeePage from "./pages/dashboard/employees/employee/EmployeePage";
import DishesPage from "./pages/dashboard/canteen/dishes/index/DishesPage";
import DishPage from "./pages/dashboard/canteen/dishes/dish/DishPage";
import OrdersPage from "./pages/dashboard/canteen/orders/index/OrdersPage";
import OrderPage from "./pages/dashboard/canteen/orders/order/OrderPage";
import OrdersPreparePage from "./pages/dashboard/canteen/orders/prepare/OrdersPreparePage";
import OrdersLogPage from "./pages/dashboard/canteen/orders/log/OrdersLogPage";
import NotFoundPage from "./pages/error/notFound/notFoundPage";
import GuestGuard from "./middlewares/GuestGuard";
import AuthGuard from "./middlewares/AuthGuard";
import PermissionGuard from "./middlewares/PermissionGuard";
import CompanyTypeGuard from "./middlewares/CompanyTypeGuard";
import { useParameterContext } from "./context/ParameterContext";

function AppRoutes() {
  const authContext = useAuthContext();
  const languageContext = useLanguageContext();
  const parameterContext = useParameterContext();

  return !authContext.fetchingUser &&
    languageContext.initialized &&
    parameterContext.initialized ? (
    <Routes>
      {/* Public routes */}
      <Route element={<GuestGuard />}>
        <Route path="login" element={<LoginPage />} />
      </Route>

      {/* Private routes */}
      <Route element={<AuthGuard />}>
        <Route path="dashboard" element={<DashboardLayout />}>
          {/* Generic routes */}
          <Route index element={<DashboardPage />} />

          {/* Users routes */}
          <Route path="users">
            <Route index element={<UsersPage />} />

            <Route element={<PermissionGuard permission="users.create" />}>
              <Route path="create" element={<UserPage />} />
            </Route>

            <Route
              element={
                <PermissionGuard or={["users.update", "users.update.self"]} />
              }
            >
              <Route path=":user/edit" element={<UserPage />} />
            </Route>

            {/* Roles routes */}
            <Route path="roles" element={<CompanyTypeGuard type="default" />}>
              <Route
                element={
                  <PermissionGuard or={["roles.read", "roles.read.self"]} />
                }
              >
                <Route index element={<RolesPage />} />
              </Route>

              <Route element={<PermissionGuard permission="roles.create" />}>
                <Route path="create" element={<RolePage />} />
              </Route>

              <Route element={<PermissionGuard permission="roles.update" />}>
                <Route path=":role/edit" element={<RolePage />} />
              </Route>
            </Route>
          </Route>

          {/* Superadmin company routes */}
          <Route element={<CompanyTypeGuard type="superadmin" />}>
            {/* Companies routes */}
            <Route path="companies">
              <Route index element={<CompaniesPage />} />
              <Route path="create" element={<CompanyPage />} />
              <Route path=":company/edit" element={<CompanyPage />} />
            </Route>

            {/* Announcements routes */}
            <Route path="announcements">
              <Route index element={<AnnouncementsPage />} />
              <Route path="create" element={<AnnouncementPage />} />
              <Route path=":announcement/edit" element={<AnnouncementPage />} />
            </Route>

            {/* Languages routes */}
            <Route path="languages">
              <Route index element={<LanguagesPage />} />
              <Route path="create" element={<LanguagePage />} />
              <Route path=":language/edit" element={<LanguagePage />} />
            </Route>
          </Route>

          {/* Default company routes */}
          <Route element={<CompanyTypeGuard type="default" />}>
            {/* Employees routes */}
            <Route path="employees">
              <Route
                element={
                  <PermissionGuard
                    or={["employees.read", "employees.read.self"]}
                  />
                }
              >
                <Route index element={<EmployeesPage />} />
              </Route>

              <Route
                element={<PermissionGuard permission="employees.create" />}
              >
                <Route path="create" element={<EmployeePage />} />
              </Route>

              <Route path=":employee/edit" element={<EmployeePage />} />
            </Route>

            {/* Canteen routes*/}
            <Route path="canteen">
              {/* Dishes routes */}
              <Route path="dishes">
                <Route
                  element={<PermissionGuard permission="canteen.dishes.read" />}
                >
                  <Route index element={<DishesPage />} />
                </Route>

                <Route
                  element={
                    <PermissionGuard permission="canteen.dishes.create" />
                  }
                >
                  <Route path="create" element={<DishPage />} />
                </Route>

                <Route
                  element={
                    <PermissionGuard permission="canteen.dishes.update" />
                  }
                >
                  <Route path=":dish/edit" element={<DishPage />} />
                </Route>
              </Route>

              {/* Orders routes */}
              <Route path="orders">
                <Route
                  element={
                    <PermissionGuard
                      or={["canteen.orders.read", "canteen.orders.read.self"]}
                    />
                  }
                >
                  <Route index element={<OrdersPage />} />
                </Route>

                <Route
                  element={
                    <PermissionGuard
                      or={[
                        "canteen.orders.create",
                        "canteen.orders.create.self",
                      ]}
                    />
                  }
                >
                  <Route path="create" element={<OrderPage />} />
                </Route>

                <Route
                  element={
                    <PermissionGuard
                      or={[
                        "canteen.orders.update",
                        "canteen.orders.update.self",
                      ]}
                    />
                  }
                >
                  <Route path=":order/edit" element={<OrderPage />} />
                </Route>

                <Route
                  element={
                    <PermissionGuard permission="canteen.orders.prepare" />
                  }
                >
                  <Route path="prepare" element={<OrdersPreparePage />} />
                </Route>

                <Route
                  element={
                    <PermissionGuard
                      or={[
                        "canteen.orders.log.read",
                        "canteen.orders.log.read.self",
                      ]}
                    />
                  }
                >
                  <Route path="log" element={<OrdersLogPage />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      {/* Page not found */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  ) : (
    ""
  );
}

export default AppRoutes;
