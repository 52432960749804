import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import EVAApi from "../../../../../apis/EVAApi";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { useAuthContext } from "../../../../../context/AuthContext";
import OrderPageView from "./OrderPageView";
import { useCallback } from "react";
import { useParameterContext } from "../../../../../context/ParameterContext";
import { userHasPermission } from "../../../../../helpers/Auth";
import moment from "moment";

function OrderPage() {
  const ORDERS_PAGE_URL = "/dashboard/canteen/orders";

  const navigate = useNavigate();
  const authContext = useAuthContext();
  const parameterContext = useParameterContext();
  const { setDashboardTitle } = useOutletContext();
  const { translate } = useLanguageContext();
  const { order } = useParams();

  const [showOrderOverview, setShowOrderOverview] = useState(false);
  const [employees, setEmployees] = useState({ fetched: false, records: [] });
  const [dishes, setDishes] = useState({ fetched: false, records: [] });
  const [orderData, setOrderData] = useState({
    fetched: false,
    employeeID: "",
    dishes: [],
  });
  const [submitErrors, setSubmitErrors] = useState(null);

  /**
   * This method will fetch the employees from the API
   */
  const fetchEmployees = useCallback(async () => {
    try {
      const response = await EVAApi.get("employees");
      if (response.status === 200 && response.data?.data) {
        setEmployees({ fetched: true, records: response.data.data.records });
      }
    } catch (error) {
      navigate(ORDERS_PAGE_URL);
    }
  }, [navigate]);

  /**
   * This method will fetch the dishes from the API
   */
  const fetchDishes = useCallback(async () => {
    try {
      const response = await EVAApi.get("canteen/dishes", {
        params: {
          scheduled_only: true,
          with_allergens_data: true,
          order_by: "dishes.health_score",
          sort: "asc",
        },
      });
      if (response.status === 200 && response.data?.data) {
        if (response.data.data.records.length <= 0) {
          navigate(ORDERS_PAGE_URL);
        }

        setDishes({ fetched: true, records: response.data.data.records });
      }
    } catch (error) {
      navigate(ORDERS_PAGE_URL);
    }
  }, [navigate]);

  /**
   * This method will fetch the orderData from the API
   */
  const fetchOrderData = useCallback(async () => {
    try {
      const response = await EVAApi.get(`canteen/orders/${order}`);
      if (response.status === 200 && response.data?.success) {
        if (response.data.data.status !== "pending") {
          navigate(ORDERS_PAGE_URL);
        }

        const dishes = response.data.data.order_dishes.map(
          (orderDish) => orderDish.dish
        );

        setOrderData({
          fetched: true,
          employeeID: response.data.data.employee,
          dishes: dishes,
          storedDishes: dishes,
        });
        setShowOrderOverview(true);
      }
    } catch (error) {
      navigate(ORDERS_PAGE_URL);
    }
  }, [navigate, order]);

  /**
   * UseEffect to initialize the component
   */
  useEffect(() => {
    setDashboardTitle(
      order
        ? translate("entities.actions.edit", [
            { key: "entities", value: translate("entities.order") },
          ])
        : translate("entities.actions.create", [
            { key: "entities", value: translate("entities.order") },
          ])
    );

    const orderTimeParam = parameterContext.getParameter(
      "canteen.max.order.time"
    );
    if (
      !order &&
      orderTimeParam &&
      moment(orderTimeParam.value, "HH:mm").isBefore(
        moment(moment().format("HH:mm"), "HH:mm")
      ) &&
      !userHasPermission(
        authContext.auth.user,
        "canteen.orders.create.any.time"
      )
    ) {
      navigate(ORDERS_PAGE_URL);
    } else {
      fetchEmployees();
      fetchDishes();

      if (order) {
        fetchOrderData();
      }
    }
  }, [
    setDashboardTitle,
    translate,
    navigate,
    order,
    fetchDishes,
    fetchEmployees,
    fetchOrderData,
    parameterContext,
  ]);

  /**
   * Effect to reset the submit errors on screen change
   */
  useEffect(() => {
    setSubmitErrors(null);
  }, [showOrderOverview]);

  /**
   * Determines how many times the dish with the given id has been ordered
   * @param {integer} id
   * @returns {integer}
   */
  function getOrderDishCount(id) {
    return orderData.dishes.filter((dish) => dish === id).length;
  }

  /**
   * Adds the dish with the given id to the order
   * @param {integer} id
   */
  function addDishToOrder(id) {
    //Don't add more if the order permissions is at it's max dish count
    const maxDishesParam = parameterContext.getParameter(
      "canteen.max.order.dishes"
    );
    if (
      maxDishesParam &&
      maxDishesParam.value <= orderData.dishes.length &&
      !userHasPermission(
        authContext.auth.user,
        "canteen.orders.create.unlimited.dishes"
      )
    ) {
      return;
    }

    const newDishes = orderData.dishes.slice();
    const ordered = orderData.dishes.filter((dish) => dish === id).length;

    if (getRealRemainingDishCount(id) > ordered) {
      newDishes.push(id);
    }

    setOrderData({ ...orderData, dishes: newDishes });
  }

  /**
   * This method will return how many of the given dish are still remaining, allready ordered dishes are taken into account here.
   * @param {int} id
   * @returns {int} remaining
   */
  function getRealRemainingDishCount(id) {
    let remaining = dishes.records.find((dish) => dish.id === id).remaining;
    if (orderData.storedDishes) {
      remaining += orderData.storedDishes.filter((dish) => dish === id).length;
    }
    return remaining;
  }

  /**
   * Removes the dish with the given id from the order
   * @param {integer} id
   */
  function removeDishFromOrder(id) {
    const newDishes = orderData.dishes.slice();
    const arrayIndex = newDishes.indexOf(id);
    if (arrayIndex > -1) {
      newDishes.splice(arrayIndex, 1);
    }
    setOrderData({ ...orderData, dishes: newDishes });

    if (showOrderOverview && newDishes.length <= 0) {
      setShowOrderOverview(false);
    }
  }

  /**
   * Determines which dish objects have been ordered at least once
   * @returns {array}
   */
  function getOrderedDishes() {
    return dishes.records.filter((dish) => orderData.dishes.includes(dish.id));
  }

  /**
   * Calculates the price of this order
   * @returns {decimal}
   */
  function getOrderPrice() {
    let price = 0;
    getOrderedDishes().forEach(
      (dish) => (price += getOrderDishCount(dish.id) * dish.price)
    );

    return price;
  }

  /**
   * Calculates the calories of this order
   * @returns {decimal}
   */
  function getOrderCalories() {
    let calories = 0;
    getOrderedDishes().forEach(
      (dish) => (calories += getOrderDishCount(dish.id) * dish.calories)
    );

    return calories;
  }

  /**
   * This method will get the employee for which this order will be
   * @returns {object}
   */
  function getOrderEmployee() {
    const orderEmployeeID =
      orderData.employeeID !== ""
        ? orderData.employeeID
        : authContext.auth.user.employee &&
          typeof authContext.auth.user.employee === "object"
        ? authContext.auth.user.employee.id
        : authContext.auth.user.employee;

    const orderEmployee = employees.records.find(
      (employee) => orderEmployeeID === employee.id
    );

    return orderEmployee;
  }

  /**
   * This method will return if the order employee is allergic to this order
   * @returns {boolean}
   */
  function isAllergicToOrder() {
    const orderEmployee = getOrderEmployee();
    if (!orderEmployee) {
      return false;
    }

    let allergic = false;

    orderData.dishes.forEach((orderDish) => {
      const dish = dishes.records.find((dish) => dish.id === orderDish);
      dish.allergens.forEach((dishAllergen) => {
        if (
          orderEmployee.allergens.find(
            (employeeAllergen) => employeeAllergen === dishAllergen.id
          )
        ) {
          allergic = true;
        }
      });
    });

    return (
      allergic && !window.confirm(translate("confirmations.allergic_to_order"))
    );
  }

  /**
   * This method will update the employee id on input change
   * @param {object} option
   */
  function onEmployeeIDChange(option) {
    setOrderData({ ...orderData, employeeID: option.value });
  }

  /**
   * This method will create and return the FormData object for submission
   * @returns {FormData}
   */
  function getSubmitData() {
    const formData = new FormData();
    formData.append("employee_id", orderData.employeeID);
    orderData.dishes.forEach((dish, index) =>
      formData.append(`dishes[${index}]`, dish)
    );
    if (order) {
      formData.append("_method", "PUT");
    }
    return formData;
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    setSubmitErrors(null);

    if (isAllergicToOrder()) {
      return;
    }

    try {
      const response = await EVAApi.request({
        url: order ? `canteen/orders/${order}` : "canteen/orders",
        method: "POST",
        data: getSubmitData(),
      });

      if (response.status === 200 && response.data?.success) {
        navigate(ORDERS_PAGE_URL);
      }
    } catch (error) {
      if (error.response?.data) {
        setSubmitErrors(error.response.data);
      }
    }
  }

  return (
    <OrderPageView
      order={order}
      orderData={orderData}
      dishes={dishes}
      employees={employees}
      showOrderOverview={showOrderOverview}
      setShowOrderOverview={setShowOrderOverview}
      getRealRemainingDishCount={getRealRemainingDishCount}
      getOrderDishCount={getOrderDishCount}
      addDishToOrder={addDishToOrder}
      removeDishFromOrder={removeDishFromOrder}
      getOrderPrice={getOrderPrice}
      onEmployeeIDChange={onEmployeeIDChange}
      getOrderCalories={getOrderCalories}
      onSubmit={onSubmit}
      submitErrors={submitErrors}
    />
  );
}

export default OrderPage;
