import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import EVAApi from "../../../apis/EVAApi";
import { useLanguageContext } from "../../../context/LanguageContext";
import DataTable from "../DataTable";
import DataTableColumn from "../DataTableColumn";
import defaultDataTableFetch from "../DataTableFetch";
import DataTableHeaderAction from "../DataTableHeaderAction";
import DataTableRowAction from "../DataTableRowAction";

function AnnouncementsDataTable() {
  const navigate = useNavigate();
  const { translate } = useLanguageContext();
  const [data, setData] = useState(null);
  const [filtering, setFiltering] = useState({});

  /**
   * This callback will get the rows for this dataTable
   */
  const getData = useCallback(async (filtering) => {
    const newData = await defaultDataTableFetch("announcements", filtering);
    setData(newData);
    setFiltering(filtering);
  }, []);

  /**
   * This method returns a list of datatable columns
   * @returns {object[]}
   */
  function getColumns() {
    return [
      DataTableColumn.create()
        .setName("id")
        .setLabel(translate("validation.attributes.id"))
        .setSelector((row) => row.id)
        .setHeaderStyling({ width: "0px" }),
      DataTableColumn.create()
        .setName("title")
        .setLabel(translate("validation.attributes.title"))
        .setSelector((row) => row.title),
      DataTableColumn.create()
        .setName("description")
        .setLabel(translate("validation.attributes.description"))
        .setSelector((row) => row.description),
      DataTableColumn.create()
        .setName("active_from")
        .setLabel(translate("validation.attributes.active_from"))
        .setSelector((row) => row.active_from),
      DataTableColumn.create()
        .setName("active_until")
        .setLabel(translate("validation.attributes.active_until"))
        .setSelector((row) => row.active_until),
    ];
  }

  /**
   * This method returns a list of datatable row actions
   * @returns {object[]}
   */
  function getRowActions() {
    return [
      DataTableRowAction.create()
        .setIcon((row) => faPen)
        .setCallback(handleEdit),
      DataTableRowAction.create()
        .setIcon((row) => faTrash)
        .setCallback(handleDelete),
    ];
  }

  /**
   * This method returns a list of datatable header actions
   * @returns {object[]}
   */
  function getHeaderActions() {
    return [
      DataTableHeaderAction.create().setIcon(faPlus).setCallback(handleAdd),
    ];
  }

  /**
   * This method will try and add a new record
   */
  function handleAdd() {
    navigate("/dashboard/announcements/create");
  }

  /**
   * This method will try and edit the given row
   * @param {object} row
   */
  function handleEdit(row) {
    navigate(`/dashboard/announcements/${row.id}/edit`);
  }

  /**
   * This method will try and delete the given row
   * @param {object} row
   * @returns
   */
  async function handleDelete(row) {
    if (!window.confirm(translate("confirmations.delete.announcement"))) {
      return;
    }

    const response = await EVAApi.delete(`announcements/${row.id}`);
    if (response.status === 200 && response.data?.success) {
      getData(filtering);
    }
  }

  /**
   * Return the base Datatable component
   */
  return (
    <DataTable
      data={data}
      getData={getData}
      columns={getColumns()}
      rowActions={getRowActions()}
      headerActions={getHeaderActions()}
    />
  );
}

export default AnnouncementsDataTable;
