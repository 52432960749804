import styled from "styled-components";

export const StyledCard = styled.div`
  border-radius: 0.75rem;
  box-shadow: ${(props) => props.theme.shadow.default};
  margin: ${(props) => props.margin};
`;

export const StyledCardHeader = styled.header`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  padding: ${(props) => props.padding ?? props.theme.spacing.normal};
  text-align: ${(props) => props.alignHeader};
  border-top-left-radius: ${(props) => props.theme.radius.default};
  border-top-right-radius: ${(props) => props.theme.radius.default};
`;

export const StyledCardBody = styled.div`
  height: ${(props) => props.height};
  background-color: ${(props) => props.theme.colors.primary.normal};
  color: ${(props) => props.theme.colors.on.primary};
  padding: ${(props) => props.padding ?? props.theme.spacing.normal};
  border-top-left-radius: ${(props) =>
    props.headerHidden ? props.theme.radius.default : "0px"};
  border-top-right-radius: ${(props) =>
    props.headerHidden ? props.theme.radius.default : "0px"};
  border-bottom-left-radius: ${(props) => props.theme.radius.default};
  border-bottom-right-radius: ${(props) => props.theme.radius.default};
  border: 1px solid ${(props) => props.theme.colors.primary.darkest};
`;
