import styled from "styled-components";
import { StyledButton } from "../../../../styles/ButtonStyles";

export const StyledDashboardNavButton = styled(StyledButton)`
  display: flex;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: center;
  width: 100%;
  text-align: left;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.accent.normal
      : props.theme.colors.primary.lightest};
  color: ${(props) =>
    props.active
      ? props.theme.colors.on.accent
      : props.theme.colors.on.primary};
`;

export const StyledDashboardNavButtonLabel = styled.span`
  opacity: ${(props) => (props.hide ? "0%" : "100%")};
  transition: all ${(props) => props.theme.transition.duration.default};
  white-space: nowrap;
`;
