import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import EVAApi from "../../../../apis/EVAApi";
import { useLanguageContext } from "../../../../context/LanguageContext";
import DataTable from "../../DataTable";
import DataTableColumn from "../../DataTableColumn";
import defaultDataTableFetch from "../../DataTableFetch";
import DataTableHeaderAction from "../../DataTableHeaderAction";
import DataTableRowAction from "../../DataTableRowAction";
import { useAuthContext } from "../../../../context/AuthContext";
import { userHasPermission } from "../../../../helpers/Auth";
import moment from "moment";
import { useParameterContext } from "../../../../context/ParameterContext";

function OrdersDataTable() {
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const parameterContext = useParameterContext();
  const { translate } = useLanguageContext();

  const [data, setData] = useState(null);
  const [filtering, setFiltering] = useState({});

  /**
   * This callback will get the rows for this dataTable
   */
  const getData = useCallback(async (filtering) => {
    const newData = await defaultDataTableFetch("canteen/orders", {
      ...filtering,
      with_employee_data: true,
    });
    setData(newData);
    setFiltering(filtering);
  }, []);

  /**
   * This method returns a list of datatable columns
   * @returns {object[]}
   */
  function getColumns() {
    return [
      DataTableColumn.create()
        .setName("id")
        .setLabel(translate("validation.attributes.id"))
        .setSelector((row) => row.id)
        .setHeaderStyling({ width: "0px" }),
      DataTableColumn.create()
        .setName("employees.name")
        .setLabel(translate("entities.employee"))
        .setSelector((row) => row.employee.name),
      DataTableColumn.create()
        .setName("status")
        .setLabel(translate("validation.attributes.status"))
        .setSelector((row) => translate("statuses." + row.status)),
    ];
  }

  /**
   * This method returns a list of datatable row actions
   * @returns {object[]}
   */
  function getRowActions() {
    return [
      DataTableRowAction.create()
        .setIcon((row) => faPen)
        .setCallback(handleEdit)
        .setDisableOn(
          (row) =>
            row.status !== "pending" ||
            (row.employee.id !== authContext.auth.user.employee &&
              !userHasPermission(
                authContext.auth.user,
                "canteen.orders.update"
              )) ||
            (row.employee.id === authContext.auth.user.employee &&
              !userHasPermission(
                authContext.auth.user,
                "canteen.orders.update.self"
              ) &&
              !userHasPermission(
                authContext.auth.user,
                "canteen.orders.update"
              ))
        ),
      DataTableRowAction.create()
        .setIcon((row) => faTrash)
        .setCallback(handleDelete)
        .setDisableOn(
          (row) =>
            row.status !== "pending" ||
            (row.employee.id !== authContext.auth.user.employee &&
              !userHasPermission(
                authContext.auth.user,
                "canteen.orders.delete"
              )) ||
            (row.employee.id === authContext.auth.user.employee &&
              !userHasPermission(
                authContext.auth.user,
                "canteen.orders.delete.self"
              ) &&
              !userHasPermission(
                authContext.auth.user,
                "canteen.orders.delete"
              ))
        ),
    ];
  }

  /**
   * This method returns a list of datatable header actions
   * @returns {object[]}
   */
  function getHeaderActions() {
    const orderTimeParam = parameterContext.getParameter(
      "canteen.max.order.time"
    );

    return [
      DataTableHeaderAction.create()
        .setLabel(translate("main.cook"))
        .setCallback(handlePrepare)
        .setDisabled(
          !data ||
            data.records?.length <= 0 ||
            !userHasPermission(authContext.auth.user, "canteen.orders.prepare")
        ),
      DataTableHeaderAction.create()
        .setIcon(faPlus)
        .setCallback(handleAdd)
        .setDisabled(
          (data?.records?.length > 0 && data.records[0].status !== "pending") ||
            (!userHasPermission(
              authContext.auth.user,
              "canteen.orders.create"
            ) &&
              !userHasPermission(
                authContext.auth.user,
                "canteen.orders.create.self"
              )) ||
            (!userHasPermission(
              authContext.auth.user,
              "canteen.orders.create.any.time"
            ) &&
              orderTimeParam &&
              moment(orderTimeParam.value, "HH:mm").isBefore(
                moment(moment().format("HH:mm"), "HH:mm")
              ))
        ),
    ];
  }

  /**
   * This method will try and edit the given row
   * @param {object} row
   */
  function handleEdit(row) {
    navigate(`/dashboard/canteen/orders/${row.id}/edit`);
  }

  /**
   * This method will try and delete the given row
   * @param {object} row
   * @returns
   */
  async function handleDelete(row) {
    if (!window.confirm(translate("confirmations.delete.order"))) {
      return;
    }

    const response = await EVAApi.delete(`canteen/orders/${row.id}`);
    if (response.status === 200 && response.data?.success) {
      getData(filtering);
    }
  }

  /**
   * This method will try and add a new record
   */
  function handleAdd() {
    navigate("/dashboard/canteen/orders/create");
  }

  /**
   * This method will set all orders to the preparing state
   * @returns
   */
  async function handlePrepare() {
    if (data?.records?.length > 0 && data.records[0].status === "pending") {
      if (!window.confirm(translate("confirmations.prepare.order"))) {
        return;
      }

      const response = await EVAApi.post("canteen/orders/prepare");
      if (response.status === 200 && response.data?.success) {
        getData(filtering);
      }
    }

    navigate("/dashboard/canteen/orders/prepare");
  }

  /**
   * Return the base Datatable component
   */
  return (
    <DataTable
      data={data}
      getData={getData}
      columns={getColumns()}
      rowActions={getRowActions()}
      headerActions={getHeaderActions()}
    />
  );
}

export default OrdersDataTable;
