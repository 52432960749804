import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

function GuestGuard() {
  const authContext = useAuthContext();
  const outletContext = useOutletContext();

  if (authContext.auth.token !== null) {
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet context={outletContext} />;
}

export default GuestGuard;
