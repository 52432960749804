import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.colors.primary.dark};
    color: ${(props) => props.theme.colors.on.primary};
    font-size: ${(props) => props.theme.font.size.default};
    overflow-x: hidden;
  }

  small {
    font-size: ${(props) => props.theme.font.size.sm};
  }

  h1, h2, h3 {
    font-weight: bold;
  }

  h1 {
    font-size: ${(props) => props.theme.font.size.xl};
  }

  h2 {
    font-size: ${(props) => props.theme.font.size.lg};
  }

  textarea {
    resize: none;
  }

  img {
    object-fit: cover;
    border-radius: ${(props) => props.theme.radius.default};
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.primary.light};
    border-radius: 2.5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.accent.normal};
    border-radius: 2.5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.accent.dark};
  }
`;

export default GlobalStyles;
