import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledCounter,
  StyledCounterMinus,
  StyledCounterPlus,
  StyledCounterValue,
} from "./CounterStyles";

function Counter({ value = null, onMinusClick, onPlusClick }) {
  return (
    <StyledCounter>
      <StyledCounterMinus onClick={onMinusClick}>
        <FontAwesomeIcon icon={faMinus} />
      </StyledCounterMinus>

      <StyledCounterValue show={Number.isInteger(value)}>
        {value}
      </StyledCounterValue>

      <StyledCounterPlus onClick={onPlusClick}>
        <FontAwesomeIcon icon={faPlus} />
      </StyledCounterPlus>
    </StyledCounter>
  );
}

export default Counter;
