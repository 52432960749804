import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../../context/LanguageContext";
import Card from "../../../../components/card/Card";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../styles/LayoutStyles";
import InputWrapper from "../../../../components/inputWrapper/InputWrapper";
import { StyledInput } from "../../../../styles/InputStyles";
import { StyledButton } from "../../../../styles/ButtonStyles";
import TranslationsDataTable from "../../../../components/dataTable/custom/TranslationsDataTable";

function LanguagePageView({
  language,
  languageData,
  onCodeChange,
  onLabelChange,
  onSubmit,
  submitErrors,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return !language || languageData.fetched ? (
    <>
      <Card
        header={translate("main.data")}
        margin={`0px 0px ${theme.spacing.large} 0px`}
      >
        <StyledFlexLayout margin={`0px 0px ${theme.spacing.normal} 0px`}>
          <StyledFlexLayoutItem>
            <InputWrapper
              label={translate("validation.attributes.code")}
              error={submitErrors?.errors?.code?.[0]}
              required
            >
              <StyledInput
                type="text"
                value={languageData.code}
                placeholder={translate("validation.placeholders.code.language")}
                onChange={onCodeChange}
                disabled={languageData.default}
              />
            </InputWrapper>
          </StyledFlexLayoutItem>

          <StyledFlexLayoutItem>
            <InputWrapper
              label={translate("validation.attributes.label")}
              error={submitErrors?.errors?.label?.[0]}
              required
            >
              <StyledInput
                type="text"
                value={languageData.label}
                placeholder={translate(
                  "validation.placeholders.label.language"
                )}
                onChange={onLabelChange}
                disabled={languageData.default}
              />
            </InputWrapper>
          </StyledFlexLayoutItem>
        </StyledFlexLayout>

        {!languageData.default ? (
          <StyledButton onClick={onSubmit}>
            {translate("main.save")}
          </StyledButton>
        ) : (
          ""
        )}
      </Card>

      {languageData.fetched ? (
        <TranslationsDataTable language={language} />
      ) : (
        ""
      )}
    </>
  ) : (
    ""
  );
}

export default LanguagePageView;
