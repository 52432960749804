import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import EVAApi from "../../../../apis/EVAApi";
import { useLanguageContext } from "../../../../context/LanguageContext";
import EmployeePageView from "./EmployeePageView";
import { useAuthContext } from "../../../../context/AuthContext";
import { userHasPermission } from "../../../../helpers/Auth";

function EmployeePage() {
  const EMPLOYEES_PAGE_URL = "/dashboard/employees";

  const navigate = useNavigate();
  const authContext = useAuthContext();

  const { employee } = useParams();
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();

  const [allergens, setAllergens] = useState({ fetched: false, records: [] });
  const [employeeData, setEmployeeData] = useState({
    fetched: false,
    name: "",
    email: "",
    present: false,
    allergens: [],
  });
  const [submitErrors, setSubmitErrors] = useState(null);

  //Block this page if the user does not have the permission to edit this employee
  if (
    employee &&
    employee != authContext.auth.user.employee &&
    !userHasPermission(authContext.auth.user, "employees.update")
  ) {
    navigate("/dashboard");
  }

  //Block this page if the user does not have the permission to edit his own employee
  if (
    employee &&
    employee == authContext.auth.user.employee &&
    !userHasPermission(authContext.auth.user, "employees.update.self")
  ) {
    navigate("/dashboard");
  }

  /**
   * This method will fetch the employee data from the API
   */
  const fetchEmployeeData = useCallback(async () => {
    try {
      const response = await EVAApi.get(`employees/${employee}`);
      if (response.status === 200 && response.data?.data) {
        setEmployeeData({
          fetched: true,
          name: response.data.data.name,
          email: response.data.data.email,
          present: response.data.data.present,
          allergens: response.data.data.allergens,
        });
      }
    } catch (error) {
      navigate(EMPLOYEES_PAGE_URL);
    }
  }, [employee, navigate]);

  /**
   * Fetch the allergens from the API
   */
  const fetchAllergens = useCallback(async () => {
    try {
      const response = await EVAApi.get("canteen/allergens");
      if (response.status === 200 && response.data?.data) {
        setAllergens({ fetched: true, records: response.data.data.records });
      }
    } catch (error) {
      navigate(EMPLOYEES_PAGE_URL);
    }
  }, [navigate]);

  /**
   * This effect will initialize the page
   */
  useEffect(() => {
    setDashboardTitle(
      employee
        ? translate("entities.actions.edit", [
            { key: "entities", value: translate("entities.employee") },
          ])
        : translate("entities.actions.create", [
            { key: "entities", value: translate("entities.employee") },
          ])
    );

    fetchAllergens();

    if (employee) {
      fetchEmployeeData();
    }
  }, [
    employee,
    fetchEmployeeData,
    fetchAllergens,
    setDashboardTitle,
    translate,
  ]);

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setEmployeeData({ ...employeeData, name: e.target.value });
  }

  /**
   * This method will update the email on input change
   * @param {event} e
   */
  function onEmailChange(e) {
    setEmployeeData({ ...employeeData, email: e.target.value });
  }

  /**
   * This method will update the present on input change
   * @param {object} option
   */
  function onPresentChange(option) {
    setEmployeeData({ ...employeeData, present: option.value });
  }

  /**
   * This method will update the allergens on input change
   * @param {object[]} options
   */
  function onAllergensChange(options) {
    setEmployeeData({
      ...employeeData,
      allergens: options.map((option) => option.value),
    });
  }

  /**
   * This method will create and return the FormData object for submission
   * @returns {FormData}
   */
  function getSubmitData() {
    const formData = new FormData();
    formData.append("name", employeeData.name);
    formData.append("email", employeeData.email);
    formData.append("present", employeeData.present ? 1 : 0);
    employeeData.allergens.forEach((allergen, index) => {
      formData.append(`allergens[${index}]`, allergen);
    });
    if (employee) {
      formData.append("_method", "PUT");
    }
    return formData;
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    setSubmitErrors(null);

    try {
      const response = await EVAApi.request({
        url: employee ? `employees/${employee}` : "employees",
        method: "POST",
        data: getSubmitData(),
      });

      if (response.status === 200 && response.data?.success) {
        navigate(EMPLOYEES_PAGE_URL);
      }
    } catch (error) {
      if (error.response?.data) {
        setSubmitErrors(error.response.data);
      }
    }
  }

  return (
    <EmployeePageView
      employee={employee}
      employeeData={employeeData}
      allergens={allergens}
      onNameChange={onNameChange}
      onEmailChange={onEmailChange}
      onPresentChange={onPresentChange}
      onAllergensChange={onAllergensChange}
      onSubmit={onSubmit}
      submitErrors={submitErrors}
    />
  );
}

export default EmployeePage;
