import { StyledCard, StyledCardHeader, StyledCardBody } from "./CardStyles";

function Card({
  header,
  alignHeader,
  margin,
  children,
  bodyPadding,
  bodyHeight,
}) {
  return (
    <StyledCard margin={margin}>
      <StyledCardHeader alignHeader={alignHeader} hidden={!header}>
        <h2>{header}</h2>
      </StyledCardHeader>

      <StyledCardBody
        height={bodyHeight}
        padding={bodyPadding}
        headerHidden={!header}
      >
        {children}
      </StyledCardBody>
    </StyledCard>
  );
}

export default Card;
