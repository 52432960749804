import {
  faBriefcase,
  faBuilding,
  faBullhorn,
  faBurger,
  faDownLeftAndUpRightToCenter,
  faFileInvoiceDollar,
  faFlag,
  faHome,
  faList,
  faSignOut,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import {
  StyledDashboardContainer,
  StyledDashboardHeader,
  StyledDashboardHeaderUser,
  StyledDashboardHeaderUserContainer,
  StyledDashboardMain,
  StyledDashboardMainContainer,
  StyledDashboardNav,
  StyledDashboardNavContainer,
  StyledDashboardNavFoldoutButton,
  StyledDashboardNavLogo,
  StyledDashboardNavLogoContainer,
} from "./DashboardLayoutStyles";
import { useLanguageContext } from "../../context/LanguageContext";
import DashboardNavButton from "./buttons/nav/DashboardNavButton";
import { StyledIconButton } from "../../styles/ButtonStyles";
import DashboardNavButtonGroup from "./buttons/nav/group/DashboardNavButtonGroup";
import { userHasPermission } from "../../helpers/Auth";

function DashboardLayout() {
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();
  const [dashboardTitle, setDashboardTitle] = useState("");
  const [navFolded, setNavFolded] = useState(
    localStorage.getItem("nav_folded") === "true"
  );

  /**
   * This method will change the nav folded status and store it in localstorage
   *
   * @param {boolean} value
   */
  function updateNavFolded(value) {
    setNavFolded(value);
    localStorage.setItem("nav_folded", value);
  }

  /**
   * This method will logout the authenticated user
   */
  function handleLogout() {
    authContext.logout();
  }

  return (
    <StyledDashboardContainer>
      <StyledDashboardNavContainer width={navFolded ? "60px" : "200px"}>
        <StyledDashboardNavLogoContainer>
          {/* Dashboard EVA Logo */}
          <StyledDashboardNavLogo
            src="/assets/images/logo.png"
            alt="EVA"
            hide={navFolded}
          />

          {/* Dashboard Navigation Foldout Button */}
          <StyledDashboardNavFoldoutButton
            onClick={() => updateNavFolded(!navFolded)}
          >
            <FontAwesomeIcon
              icon={
                navFolded
                  ? faUpRightAndDownLeftFromCenter
                  : faDownLeftAndUpRightToCenter
              }
            />
          </StyledDashboardNavFoldoutButton>
        </StyledDashboardNavLogoContainer>

        {/* Dashboard Navigation */}
        <StyledDashboardNav>
          {/* General group */}
          <DashboardNavButtonGroup
            label={translate("main.general")}
            navFolded={navFolded}
          >
            {/* Dashboard */}
            <DashboardNavButton
              icon={faHome}
              label={translate("main.dashboard")}
              pathname="/dashboard"
              folded={navFolded}
              onlyActiveOnSelf={true}
              show={true}
            />

            {/* Companies */}
            <DashboardNavButton
              icon={faBuilding}
              label={translate("entities.companies")}
              pathname="/dashboard/companies"
              folded={navFolded}
              show={
                authContext.auth.user?.role_type === "superadmin" &&
                authContext.auth.user.company?.type === "superadmin"
              }
            />

            {/* Announcements */}
            <DashboardNavButton
              icon={faBullhorn}
              label={translate("entities.announcements")}
              pathname="/dashboard/announcements"
              folded={navFolded}
              show={
                authContext.auth.user?.role_type === "superadmin" &&
                authContext.auth.user.company?.type === "superadmin"
              }
            />

            {/* Languages */}
            <DashboardNavButton
              icon={faFlag}
              label={translate("entities.languages")}
              pathname="/dashboard/languages"
              folded={navFolded}
              show={
                authContext.auth.user?.role_type === "superadmin" &&
                authContext.auth.user.company?.type === "superadmin"
              }
            />

            {/* Users */}
            <DashboardNavButton
              icon={faUsers}
              label={translate("entities.users")}
              pathname="/dashboard/users"
              folded={navFolded}
              show={true}
            />

            {/* Employees  */}
            <DashboardNavButton
              icon={faBriefcase}
              label={translate("entities.employees")}
              pathname="/dashboard/employees"
              folded={navFolded}
              show={
                authContext.auth.user.company?.type === "default" &&
                (userHasPermission(authContext.auth.user, "employees.read") ||
                  userHasPermission(
                    authContext.auth.user,
                    "employees.read.self"
                  ))
              }
            />
          </DashboardNavButtonGroup>

          {/* Canteen group */}
          <DashboardNavButtonGroup
            label={translate("main.canteen")}
            navFolded={navFolded}
          >
            {/* Dishes */}
            <DashboardNavButton
              icon={faBurger}
              label={translate("entities.dishes")}
              pathname="/dashboard/canteen/dishes"
              folded={navFolded}
              show={
                authContext.auth.user.company?.type === "default" &&
                userHasPermission(authContext.auth.user, "canteen.dishes.read")
              }
            />

            {/* Orders */}
            <DashboardNavButton
              icon={faFileInvoiceDollar}
              label={translate("entities.orders")}
              pathname="/dashboard/canteen/orders"
              excludeFromPath={["log"]}
              folded={navFolded}
              show={
                authContext.auth.user.company?.type === "default" &&
                (userHasPermission(
                  authContext.auth.user,
                  "canteen.orders.read"
                ) ||
                  userHasPermission(
                    authContext.auth.user,
                    "canteen.orders.read.self"
                  ))
              }
            />

            {/* Orders log */}
            <DashboardNavButton
              icon={faList}
              label={translate("entities.order_log")}
              pathname="/dashboard/canteen/orders/log"
              folded={navFolded}
              show={
                authContext.auth.user.company?.type === "default" &&
                (userHasPermission(
                  authContext.auth.user,
                  "canteen.orders.log.read"
                ) ||
                  userHasPermission(
                    authContext.auth.user,
                    "canteen.orders.log.read.self"
                  ))
              }
            />
          </DashboardNavButtonGroup>
        </StyledDashboardNav>
      </StyledDashboardNavContainer>

      <StyledDashboardMainContainer>
        {/* Dashboard Header */}
        <StyledDashboardHeader>
          <h1>{dashboardTitle}</h1>

          <StyledDashboardHeaderUserContainer>
            {/* Dashboard User Info */}
            <StyledDashboardHeaderUser>
              <FontAwesomeIcon icon={faUser} />
              {authContext.auth.user.name}
            </StyledDashboardHeaderUser>

            {/* Dashboard User Logout */}
            <StyledIconButton onClick={() => handleLogout()}>
              <FontAwesomeIcon icon={faSignOut} />
            </StyledIconButton>
          </StyledDashboardHeaderUserContainer>
        </StyledDashboardHeader>

        {/* Dashboard Main Content */}
        <StyledDashboardMain>
          <Outlet context={{ setDashboardTitle }} />
        </StyledDashboardMain>
      </StyledDashboardMainContainer>
    </StyledDashboardContainer>
  );
}

export default DashboardLayout;
