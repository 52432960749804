import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useLanguageContext } from "../../../../context/LanguageContext";
import UsersPageView from "./UsersPageView";

function UsersPage() {
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();

  useEffect(() => {
    setDashboardTitle(translate("entities.users"));
  }, [setDashboardTitle, translate]);

  return <UsersPageView />;
}

export default UsersPage;
