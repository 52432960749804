import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import EVAApi from "../apis/EVAApi";

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  const [translations, setTranslations] = useState([]);
  const [language, setLanguage] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [initialized, setInitialized] = useState(false);

  /**
   * This method will update the language to the given value
   * @param {object} newLanguage
   */
  const updateLanguage = useCallback(async (newLanguage) => {
    setLanguage(newLanguage);

    if (newLanguage) {
      localStorage.setItem("language", newLanguage.code);
      fetchTranslations();
    } else {
      localStorage.removeItem("language");
      setTranslations([]);
    }
  }, []);

  /**
   * This method will fetch the languages from the API
   */
  const fetchLanguages = useCallback(async () => {
    const storedLanguage = localStorage.getItem("language");

    try {
      const response = await EVAApi.get("languages");
      if (response.status === 200 && response.data?.success) {
        let defaultLanguage = response.data.data.records.find(
          (record) => record.code === storedLanguage
        );

        if (!defaultLanguage) {
          defaultLanguage = response.data.data.records.find(
            (record) => record.default === true
          );
        }

        setLanguages(response.data.data.records);
        updateLanguage(defaultLanguage);
      }
    } catch (error) {
      updateLanguage(null);
      setLanguages([]);
      setInitialized(true);
    }
  }, [updateLanguage]);

  /**
   * Fetches the translations with the current language code from the EVA backend API and sets the translations
   */
  const fetchTranslations = async () => {
    try {
      const response = await EVAApi.get("translations");
      if (response.status === 200 && response.data?.success) {
        setTranslations(response.data.data.records);
      }
    } catch (error) {
      setTranslations([]);
    } finally {
      setInitialized(true);
    }
  };

  /**
   * Tries to find the translation that belongs to the given key
   *
   * @param {string} key the key of the translation
   * @param {object[]} replace array of key value objects to replace chars
   * @returns {string}
   */
  function translate(key, replace = []) {
    let translation = translations.find((record) => record.key === key);
    if (!translation) {
      return key;
    }

    let translationValue = translation.value.slice();
    replace.forEach((replaceItem) => {
      translationValue = translationValue.replace(
        ":" + replaceItem.key,
        replaceItem.value
      );
    });

    return translationValue;
  }

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  return (
    <LanguageContext.Provider
      value={{ translate, initialized, language, languages }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguageContext() {
  return useContext(LanguageContext);
}
