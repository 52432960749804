import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useLanguageContext } from "../../../../context/LanguageContext";
import LanguagesPageView from "./LanguagesPageView";

function LanguagesPage() {
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();

  useEffect(() => {
    setDashboardTitle(translate("entities.languages"));
  }, [setDashboardTitle, translate]);

  return <LanguagesPageView />;
}

export default LanguagesPage;
