import { useEffect } from "react";
import DashboardPageView from "./DashboardPageView";
import { useOutletContext } from "react-router-dom";
import { useLanguageContext } from "../../../context/LanguageContext";

function DashboardPage() {
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();

  useEffect(() => {
    setDashboardTitle(translate("main.dashboard"));
  }, [setDashboardTitle, translate]);

  return <DashboardPageView />;
}

export default DashboardPage;
