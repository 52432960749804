import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledButton,
  StyledIconButton,
  StyledTextButton,
} from "../../../styles/ButtonStyles";
import Card from "../../../components/card/Card";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../styles/LayoutStyles";
import {
  StyledLoginAnnouncementContainer,
  StyledLoginAnnouncementsHeader,
  StyledLoginAnnouncementsOverlay,
  StyledLoginLogo,
  StyledLoginWidthConstraint,
} from "./LoginPageStyles";
import { faBell, faClose } from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useTheme } from "styled-components";
import InputWrapper from "../../../components/inputWrapper/InputWrapper";
import { StyledInput } from "../../../styles/InputStyles";
import { StyledInputError } from "../../../components/inputWrapper/InputWrapperStyles";

function LoginPageView({
  loginData,
  announcements,
  onEmailChange,
  onPasswordChange,
  onSubmit,
  submitErrors,
  showAnnouncements,
  setShowAnnouncements,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      <StyledLoginWidthConstraint>
        <StyledLoginLogo src="/assets/images/logo.png" alt="EVA Logo" />

        <Card
          header={
            <StyledFlexLayout>
              <StyledFlexLayoutItem>
                {translate("auth.login")}
              </StyledFlexLayoutItem>

              {announcements.records.length > 0 ? (
                <StyledTextButton
                  color={`${theme.colors.accent.normal}`}
                  onClick={() => setShowAnnouncements(true)}
                >
                  <FontAwesomeIcon icon={faBell} />{" "}
                  <small>({announcements.records.length})</small>
                </StyledTextButton>
              ) : (
                ""
              )}
            </StyledFlexLayout>
          }
        >
          <InputWrapper
            for="email"
            label={translate("validation.attributes.email")}
            required
            error={submitErrors?.errors?.email?.[0]}
            margin={`0px 0px ${theme.spacing.normal} 0px`}
          >
            <StyledInput
              type="text"
              id="email"
              name="email"
              value={loginData.email}
              onChange={onEmailChange}
              placeholder={translate("validation.placeholders.email")}
              margin={`0px 0px ${theme.spacing.normal}, 0px`}
            />
          </InputWrapper>

          <InputWrapper
            for="password"
            label={translate("validation.attributes.password")}
            required
            error={submitErrors?.errors?.password?.[0]}
            margin={`0px 0px ${theme.spacing.normal} 0px`}
          >
            <StyledInput
              type="password"
              id="password"
              name="password"
              value={loginData.password}
              onChange={onPasswordChange}
              placeholder={translate("validation.placeholders.password")}
            />
          </InputWrapper>

          <StyledFlexLayout direction="column" alignItems="start" gap="0px">
            {submitErrors?.message && !submitErrors?.errors ? (
              <StyledInputError>{submitErrors.message}</StyledInputError>
            ) : (
              ""
            )}

            <StyledButton onClick={onSubmit}>
              {translate("main.proceed")}
            </StyledButton>
          </StyledFlexLayout>
        </Card>
      </StyledLoginWidthConstraint>

      {/* Announcements overlay display */}
      <StyledLoginAnnouncementsOverlay active={showAnnouncements}>
        <StyledLoginWidthConstraint>
          {/* Announcements header */}
          <StyledLoginAnnouncementsHeader>
            <StyledFlexLayout alignItems="center">
              <FontAwesomeIcon icon={faBell} />

              <StyledFlexLayoutItem>
                <h1>{translate("entities.announcements")}</h1>
              </StyledFlexLayoutItem>

              <StyledIconButton onClick={() => setShowAnnouncements(false)}>
                <FontAwesomeIcon icon={faClose} />
              </StyledIconButton>
            </StyledFlexLayout>
          </StyledLoginAnnouncementsHeader>

          {/* Announcements */}
          {announcements.records.map((announcement, index) => (
            <StyledLoginAnnouncementContainer key={index}>
              <h2>{announcement.title}</h2>
              <p>{announcement.description}</p>
            </StyledLoginAnnouncementContainer>
          ))}
        </StyledLoginWidthConstraint>
      </StyledLoginAnnouncementsOverlay>
    </>
  );
}

export default LoginPageView;
