import { useLanguageContext } from "../../context/LanguageContext";
import { StyledSelect } from "./SelectStyles";

function Select({ id, name, isMulti, options, value, onChange, disabled }) {
  const { translate } = useLanguageContext();

  return (
    <StyledSelect
      id={id}
      name={name}
      isMulti={isMulti}
      classNamePrefix="react-select"
      placeholder={translate("main.select")}
      options={options}
      value={value}
      onChange={onChange}
      noOptionsMessage={() => translate("main.no_results")}
      isDisabled={disabled}
    />
  );
}

export default Select;
