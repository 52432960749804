import React, { useCallback, useContext, useEffect, useState } from "react";
import EVAApi from "../apis/EVAApi";

const ParameterContext = React.createContext();

export function ParameterProvider({ children }) {
  const [parameters, setParameters] = useState([]);
  const [initialized, setInitialized] = useState(false);

  /**
   * This callback will fetch the parameters from the EVA API
   */
  const fetchParameters = useCallback(async () => {
    try {
      const response = await EVAApi.get("parameters");
      if (response.status === 200 && response.data?.success) {
        setParameters(response.data.data.records);
      }
    } catch (error) {
      //
    } finally {
      setInitialized(true);
    }
  }, []);

  /**
   * This method will find and return the parameter with the given key
   */
  const getParameter = (key) => {
    return parameters.find((param) => param.key === key);
  };

  /**
   * This useEffect will initialize the component
   */
  useEffect(() => {
    fetchParameters();
  }, [fetchParameters]);

  return (
    <ParameterContext.Provider value={{ getParameter, initialized }}>
      {children}
    </ParameterContext.Provider>
  );
}

export function useParameterContext() {
  return useContext(ParameterContext);
}
