import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { StyledInput } from "../../../../../styles/InputStyles";
import InputWrapper from "../../../../../components/inputWrapper/InputWrapper";
import { StyledInputError } from "../../../../../components/inputWrapper/InputWrapperStyles";
import { StyledButton } from "../../../../../styles/ButtonStyles";
import Card from "../../../../../components/card/Card";
import ImageInput from "../../../../../components/imageInput/ImageInput";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../../styles/LayoutStyles";
import SchedulingForm, {
  correctScheduleInput,
} from "../../../../../components/schedulingForm/SchedulingForm";
import Select from "../../../../../components/select/Select";
import { getPublicEVAImage } from "../../../../../helpers/EVAFileManager";

function DishPageView({
  dish,
  dishData,
  allergens,
  onImageChange,
  onNameChange,
  onPriceChange,
  onCapacityChange,
  onCaloriesChange,
  onHealthScoreChange,
  onCategoryChange,
  onDescriptionChange,
  onAllergensChange,
  onScheduleChange,
  onSubmit,
  submitErrors,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  //Define the allergens input options
  const allergensInputOptions = allergens.records.map((allergen) => {
    return {
      value: allergen.id,
      label: translate(`allergens.${allergen.name}`),
    };
  });

  return allergens.fetched && (!dish || dishData.fetched) ? (
    <>
      <Card header={translate("main.data")}>
        <div>
          <StyledFlexLayout
            margin={`0px 0px ${theme.spacing.normal} 0px`}
            alignItems="stretch"
          >
            {/* Image input */}
            <InputWrapper error={submitErrors?.errors?.image?.[0]}>
              <ImageInput
                width={"175px"}
                height={"100%"}
                src={getPublicEVAImage(dishData.image)}
                alt={translate("entities.dish")}
                onImageChange={onImageChange}
              />
            </InputWrapper>

            <StyledFlexLayoutItem>
              <StyledFlexLayout
                margin={`0px 0px ${theme.spacing.normal} 0px`}
                alignItems="start"
              >
                <StyledFlexLayoutItem>
                  {/* Name input */}
                  <InputWrapper
                    label={translate("validation.attributes.name")}
                    required
                    error={submitErrors?.errors?.name?.[0]}
                  >
                    <StyledInput
                      type="text"
                      placeholder={translate(
                        "validation.placeholders.name.dish"
                      )}
                      value={dishData.name}
                      onChange={onNameChange}
                    />
                  </InputWrapper>
                </StyledFlexLayoutItem>

                {/* Price input */}
                <InputWrapper
                  label={translate("validation.attributes.price")}
                  required
                  error={submitErrors?.errors?.price?.[0]}
                >
                  <StyledInput
                    type="number"
                    value={dishData.price}
                    onChange={onPriceChange}
                  />
                </InputWrapper>

                {/* Capacity input */}
                <InputWrapper
                  label={translate("validation.attributes.capacity")}
                  required
                  error={submitErrors?.errors?.capacity?.[0]}
                >
                  <StyledInput
                    type="number"
                    value={dishData.capacity}
                    onChange={onCapacityChange}
                  />
                </InputWrapper>
              </StyledFlexLayout>

              {/* Description input */}
              <InputWrapper
                label={translate("validation.attributes.description")}
                error={submitErrors?.errors?.description?.[0]}
              >
                <StyledInput
                  as="textarea"
                  rows={4}
                  placeholder={translate(
                    "validation.placeholders.description.dish"
                  )}
                  value={dishData.description}
                  onChange={onDescriptionChange}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>
          </StyledFlexLayout>

          {/* Allergen select */}
          <InputWrapper
            label={translate("entities.allergens")}
            margin={`0px 0px ${theme.spacing.normal} 0px`}
          >
            <Select
              isMulti
              options={allergensInputOptions}
              value={allergensInputOptions.filter((option) =>
                dishData.allergens.find(
                  (dishAllergen) => dishAllergen === option.value
                )
              )}
              onChange={onAllergensChange}
            />
          </InputWrapper>

          <StyledFlexLayout style={{ marginBottom: theme.spacing.normal }}>
            <StyledFlexLayoutItem>
              <InputWrapper
                label={translate("validation.attributes.calories")}
                error={submitErrors?.errors?.calories?.[0]}
              >
                <StyledInput
                  type="number"
                  value={dishData.calories}
                  onChange={onCaloriesChange}
                  placeholder={translate("validation.attributes.calories")}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>

            <StyledFlexLayoutItem>
              <InputWrapper
                label={translate("validation.attributes.health_score")}
                error={submitErrors?.errors?.health_score?.[0]}
              >
                <StyledInput
                  type="number"
                  value={dishData.health_score}
                  onChange={onHealthScoreChange}
                  placeholder={translate("validation.attributes.health_score")}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>
          </StyledFlexLayout>

          <InputWrapper
            label={translate("validation.attributes.category")}
            error={submitErrors?.errors?.category?.[0]}
          >
            <StyledInput
              type="text"
              value={dishData.category}
              onChange={onCategoryChange}
              placeholder={translate("validation.attributes.category")}
            />
          </InputWrapper>
        </div>
      </Card>

      <SchedulingForm
        margin={`${theme.spacing.large} 0px`}
        schedule={correctScheduleInput(dishData.schedule)}
        onScheduleChange={onScheduleChange}
      />

      <StyledButton onClick={onSubmit}>{translate("main.save")}</StyledButton>

      <div>
        <StyledInputError>
          {!submitErrors?.errors ? submitErrors?.message : ""}
        </StyledInputError>
      </div>
    </>
  ) : (
    ""
  );
}

export default DishPageView;
