import { faCheck, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useState } from "react";
import EVAApi from "../../../apis/EVAApi";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledInput } from "../../../styles/InputStyles";
import DataTable from "../DataTable";
import DataTableColumn from "../DataTableColumn";
import defaultDataTableFetch from "../DataTableFetch";
import DataTableRowAction from "../DataTableRowAction";

function TranslationsDataTable({ language }) {
  const { translate } = useLanguageContext();
  const [data, setData] = useState(null);
  const [inputValues, setInputValues] = useState([]);

  /**
   * This callback will get the rows for this dataTable
   */
  const getData = useCallback(
    async (filtering) => {
      const newData = await defaultDataTableFetch("translations", filtering, {
        "Content-Language": language.code,
      });

      if (newData && newData.records) {
        const newInputValues = [];
        newData.records.forEach((row) => {
          newInputValues.push({
            key: row.id,
            value: row.value,
            saved: true,
          });
        });
        setInputValues(newInputValues);
      }

      setData(newData);
    },
    [language]
  );

  /**
   * This method returns a list of datatable columns
   * @returns {object[]}
   */
  function getColumns() {
    return [
      DataTableColumn.create()
        .setName("key")
        .setLabel(translate("validation.attributes.key"))
        .setSelector((row) => row.key)
        .setHeaderStyling({ width: "300px" }),
      DataTableColumn.create()
        .setName("value")
        .setLabel(translate("validation.attributes.value"))
        .setSelector((row) => (
          <StyledInput
            as={"textarea"}
            value={
              inputValues.find((inputValue) => inputValue.key === row.id).value
            }
            width={"100%"}
            onChange={(e) => {
              handleTranslationInputChange(row, e.target.value);
            }}
          />
        )),
    ];
  }

  /**
   * This method returns a list of dataTable row actions
   * @returns {object[]}
   */
  function getRowActions() {
    return [
      DataTableRowAction.create()
        .setIcon((row) =>
          inputValues.find((inputValue) => inputValue.key === row.id).saved
            ? faCheck
            : faFloppyDisk
        )
        .setCallback(handleTranslationSave),
    ];
  }

  /**
   * This method will save the given translation row
   * @param {object} row
   */
  async function handleTranslationSave(row) {
    const index = inputValues.findIndex(
      (inputValue) => inputValue.key === row.id
    );
    if (inputValues[index].saved) {
      return;
    }

    const response = await EVAApi.put(`translations/${row.id}`, {
      value: inputValues[index].value,
    });

    if (response.status === 200 && response.data?.success) {
      const newInputValues = [...inputValues];
      newInputValues[index] = {
        key: inputValues[index].key,
        value: inputValues[index].value,
        saved: true,
      };

      row.value = inputValues[index].value;
      setInputValues(newInputValues);
    }
  }

  /**
   * This method will handle the onChange of a translation input field
   * @param {object} row
   * @param {string} value
   */
  function handleTranslationInputChange(row, value) {
    const index = inputValues.findIndex(
      (inputValue) => inputValue.key === row.id
    );

    if (index !== -1) {
      const newInputValues = [...inputValues];
      newInputValues[index] = {
        key: row.id,
        value: value,
        saved: value === row.value,
      };
      setInputValues(newInputValues);
    }
  }

  /**
   * Return the base Datatable component
   */
  return (
    <DataTable
      data={data}
      getData={getData}
      columns={getColumns()}
      rowActions={getRowActions()}
      defaultSort="asc"
    />
  );
}

export default TranslationsDataTable;
