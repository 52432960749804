import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import OrdersLogPageView from "./OrdersLogPageView";

function OrdersLogPage() {
  const { setDashboardTitle } = useOutletContext();
  const { translate } = useLanguageContext();

  useEffect(() => {
    setDashboardTitle(translate("entities.order_log"));
  }, [setDashboardTitle, translate]);

  return <OrdersLogPageView />;
}

export default OrdersLogPage;
