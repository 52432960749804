import styled from "styled-components";

export const StyledDashboardNavButtonGroup = styled.div`
  padding-left: ${(props) => props.theme.spacing.large};
  padding-bottom: ${(props) => props.theme.spacing.normal};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledDashboardNavButtonGroupLabel = styled.p`
  color: ${(props) => props.theme.colors.primary.lightest};
  opacity: ${(props) => (props.hide ? 0 : 1)};
  transition: all ${(props) => props.theme.transition.duration.default};
`;
