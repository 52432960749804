import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useLanguageContext } from "../../../../context/LanguageContext";
import AnnouncementsPageView from "./AnnouncementsPageView";

function AnnouncementsPage() {
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();

  useEffect(() => {
    setDashboardTitle(translate("entities.announcements"));
  }, [setDashboardTitle, translate]);

  return <AnnouncementsPageView />;
}

export default AnnouncementsPage;
