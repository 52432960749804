import {
  StyledDishDisplayImage,
  StyledDishDisplayPrice,
  StyledOrderPreviewContainer,
  StyledOrderTable,
  StyledOrderTableNameTd,
  StyledOrderTableRow,
  StyledOrderTableTd,
} from "./OrderPageStyles";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import {
  StyledAltButton,
  StyledButton,
} from "../../../../../styles/ButtonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../../styles/LayoutStyles";
import Card from "../../../../../components/card/Card";
import { getPublicEVAImage } from "../../../../../helpers/EVAFileManager";
import Counter from "../../../../../components/counter/Counter";
import InputWrapper from "../../../../../components/inputWrapper/InputWrapper";
import Select from "../../../../../components/select/Select";
import { useTheme } from "styled-components";
import { StyledInputError } from "../../../../../components/inputWrapper/InputWrapperStyles";

function OrderPageView({
  order,
  orderData,
  dishes,
  employees,
  showOrderOverview,
  setShowOrderOverview,
  getRealRemainingDishCount,
  getOrderDishCount,
  addDishToOrder,
  removeDishFromOrder,
  getOrderPrice,
  getOrderCalories,
  onEmployeeIDChange,
  onSubmit,
  submitErrors,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  //Define the employee input options
  const employeeInputOptions = employees.records.map((employee) => {
    return { value: employee.id, label: employee.name };
  });
  employeeInputOptions.unshift({ value: "", label: translate("main.select") });

  return dishes.fetched &&
    employees.fetched &&
    (!order || orderData.fetched) ? (
    <div style={{ paddingBottom: "75px" }}>
      {/* Order preview button */}
      <StyledOrderPreviewContainer
        show={!showOrderOverview && orderData.dishes.length > 0}
      >
        {translate("entities.order")} {translate("main.overview").toLowerCase()}
        <StyledButton onClick={() => setShowOrderOverview(true)}>
          <FontAwesomeIcon icon={faCartShopping} /> ({orderData.dishes.length})
        </StyledButton>
      </StyledOrderPreviewContainer>

      {/* Dishes */}
      {!showOrderOverview ? (
        <StyledFlexLayout direction="column">
          {dishes.records.map((dish, index) => (
            <Card
              key={index}
              disabled={getRealRemainingDishCount(dish.id) <= 0}
            >
              <StyledFlexLayout>
                {/* Dish image */}
                <StyledDishDisplayImage
                  src={getPublicEVAImage(dish.image)}
                  alt={translate("validation.attributes.image")}
                />

                <StyledFlexLayoutItem>
                  <StyledFlexLayout>
                    {/* Dish name */}
                    <h3>{`${dish.name} (${dish.calories} kcal)`}</h3>

                    {/* Dish price */}
                    <StyledDishDisplayPrice>
                      &euro;{dish.price}
                    </StyledDishDisplayPrice>
                  </StyledFlexLayout>

                  {/* Dish description */}
                  <small>{dish.description}</small>

                  {/* Dish allergens */}
                  {dish.allergens.length > 0 && (
                    <div>
                      <small>
                        <b>{translate("entities.allergens")}: </b>
                        {dish.allergens.map(
                          (allergen, index) =>
                            translate("allergens." + allergen.name) +
                            (index !== dish.allergens.length - 1 ? ", " : "")
                        )}
                      </small>
                    </div>
                  )}

                  {/* Category */}
                  <div>
                    <small>
                      <b>
                        {translate("validation.attributes.category") + ": "}
                      </b>
                      {dish.category}
                    </small>
                  </div>

                  {/* Health score */}
                  <div>
                    <small>
                      <b>
                        {translate("validation.attributes.health_score") + ": "}
                      </b>
                      {dish.health_score}
                    </small>
                  </div>

                  {/* Remaining */}
                  <small>
                    <b>{translate("main.in_stock") + ": "}</b>
                    {getRealRemainingDishCount(dish.id)}
                  </small>
                </StyledFlexLayoutItem>

                {/* Dish counter */}
                <Counter
                  value={getOrderDishCount(dish.id)}
                  onMinusClick={() => removeDishFromOrder(dish.id)}
                  onPlusClick={() => addDishToOrder(dish.id)}
                />
              </StyledFlexLayout>
            </Card>
          ))}
        </StyledFlexLayout>
      ) : (
        ""
      )}

      {/* Order overview card */}
      {showOrderOverview ? (
        <Card header={translate("main.overview")}>
          {/* Employee id select */}
          <InputWrapper
            label={
              translate("main.for") +
              " " +
              translate("entities.employee").toLowerCase() +
              ":"
            }
            margin={`0px 0px ${theme.spacing.large} 0px`}
          >
            <Select
              options={employeeInputOptions}
              value={employeeInputOptions.find(
                (option) => option.value === orderData.employeeID
              )}
              onChange={onEmployeeIDChange}
            />
          </InputWrapper>

          <StyledOrderTable style={{ width: "100%" }}>
            {/* order dishes */}
            <tbody>
              {dishes.records
                .filter((dish) =>
                  orderData.dishes.find((orderDish) => orderDish === dish.id)
                )
                .map((dish, index) => (
                  <StyledOrderTableRow key={index}>
                    {/* Order dish count */}
                    <StyledOrderTableTd>
                      {getOrderDishCount(dish.id)}
                    </StyledOrderTableTd>

                    {/* Order dish name */}
                    <StyledOrderTableNameTd>{`${dish.name} (${
                      getOrderDishCount(dish.id) * dish.calories
                    } kcal)`}</StyledOrderTableNameTd>

                    {/* Order dish price */}
                    <StyledOrderTableTd>
                      &euro;{getOrderDishCount(dish.id) * dish.price}
                    </StyledOrderTableTd>

                    {/* Order dish counter */}
                    <StyledOrderTableTd>
                      <Counter
                        onMinusClick={() => removeDishFromOrder(dish.id)}
                        onPlusClick={() => addDishToOrder(dish.id)}
                      />
                    </StyledOrderTableTd>
                  </StyledOrderTableRow>
                ))}
            </tbody>

            <tfoot>
              <StyledOrderTableRow>
                <StyledOrderTableTd></StyledOrderTableTd>

                {/* Order total label */}
                <StyledOrderTableTd>
                  <b>{`${translate(
                    "main.total"
                  )} (${getOrderCalories()} kcal)`}</b>
                </StyledOrderTableTd>

                {/* Order total price */}
                <StyledOrderTableTd>
                  <b>&euro;{getOrderPrice()}</b>
                </StyledOrderTableTd>
              </StyledOrderTableRow>
            </tfoot>
          </StyledOrderTable>

          <StyledFlexLayout margin={`${theme.spacing.large} 0px 0px 0px`}>
            {/* Order cancel button */}
            <StyledAltButton onClick={() => setShowOrderOverview(false)}>
              {translate("main.cancel")}
            </StyledAltButton>

            {/* Order submit button */}
            <StyledButton onClick={onSubmit}>
              {translate("main.send")}
            </StyledButton>
          </StyledFlexLayout>

          <StyledInputError>{submitErrors?.message}</StyledInputError>
        </Card>
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
}

export default OrderPageView;
