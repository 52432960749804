import styled from "styled-components";

export const StyledOrdersPreparePageTable = styled.table`
  width: 100%;
`;

export const StyledOrdersPreparePageTd = styled.td`
  width: ${(props) => (props.small ? "0px" : "")};
  padding: ${(props) =>
    `${props.theme.spacing.small} ${props.theme.spacing.normal}`};
  vertical-align: top;
`;

export const StyledOrdersPreparePageTr = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
  &:last-child {
    border-bottom: 0px;
  }
`;

export const StyledOrdersPreparePageName = styled.small`
  color: ${(props) => props.theme.colors.on.primary};
  opacity: 0.5;
`;
