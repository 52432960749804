import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import EVAApi from "../../../../apis/EVAApi";
import { useAuthContext } from "../../../../context/AuthContext";
import { useLanguageContext } from "../../../../context/LanguageContext";
import UserPageView from "./UserPageView";
import { userHasPermission } from "../../../../helpers/Auth";

function UserPage() {
  const USERS_PAGE_URL = "/dashboard/users";

  const navigate = useNavigate();
  const { user } = useParams();
  const { setDashboardTitle } = useOutletContext();
  const { translate } = useLanguageContext();
  const authContext = useAuthContext();

  const [roles, setRoles] = useState({ fetched: false, records: [] });
  const [employees, setEmployees] = useState({ fetched: false, records: [] });
  const [userData, setUserData] = useState({
    fetched: false,
    name: "",
    password: "",
    employeeID: "",
    roles: [],
  });
  const [submitErrors, setSubmitErros] = useState(null);
  const userUpdateSelfOnly =
    userHasPermission(authContext.auth.user, "users.update.self") &&
    !userHasPermission(authContext.auth.user, "users.update");

  /**
   * This method will fetch the user data from the API
   */
  const fetchUserData = useCallback(async () => {
    try {
      const response = await EVAApi.get(`users/${user}`);
      if (response.status === 200 && response.data?.success) {
        if (response.data.data.email === "superadmin@evasolutions.eu") {
          navigate(USERS_PAGE_URL);
        }

        setUserData({
          fetched: true,
          name: response.data.data.name,
          email: response.data.data.email,
          password: "",
          employeeID: response.data.data.employee ?? "",
          roles: response.data.data.roles,
        });
      }
    } catch (error) {
      navigate(USERS_PAGE_URL);
    }
  }, [navigate, user]);

  /**
   * This method will fetch the employees from the API
   */
  const fetchEmployees = useCallback(async () => {
    try {
      const response = await EVAApi.get("employees");
      if (response.status === 200 && response.data?.success) {
        setEmployees({ fetched: true, records: response.data.data.records });
      }
    } catch (error) {
      navigate(USERS_PAGE_URL);
    }
  }, [navigate]);

  /**
   * This method will fetch the employees from the API
   */
  const fetchRoles = useCallback(async () => {
    try {
      const response = await EVAApi.get("roles");
      if (response.status === 200 && response.data?.success) {
        setRoles({ fetched: true, records: response.data.data.records });
      }
    } catch (error) {
      navigate(USERS_PAGE_URL);
    }
  }, [navigate]);

  /**
   * This method will initialize the component
   */
  useEffect(() => {
    setDashboardTitle(
      user
        ? translate("entities.actions.edit", [
            { key: "entities", value: translate("entities.user") },
          ])
        : translate("entities.actions.create", [
            { key: "entities", value: translate("entities.user") },
          ])
    );

    if (authContext.auth.user.company.type !== "superadmin") {
      fetchEmployees();
      fetchRoles();
    }

    if (user) {
      fetchUserData();
    }
  }, [
    setDashboardTitle,
    translate,
    authContext.auth.user.company.type,
    user,
    fetchEmployees,
    fetchRoles,
    fetchUserData,
  ]);

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setUserData({ ...userData, name: e.target.value });
  }

  /**
   * This method will update the email on input change
   * @param {event} e
   */
  function onEmailChange(e) {
    setUserData({ ...userData, email: e.target.value });
  }

  /**
   * This method will update the password on input change
   * @param {event} e
   */
  function onPasswordChange(e) {
    setUserData({ ...userData, password: e.target.value });
  }

  /**
   * This method will update the employee on input change
   * @param {object} option
   */
  function onEmployeeIDChange(option) {
    setUserData({ ...userData, employeeID: option.value });
  }

  /**
   * This method will update the roles on input change
   * @param {object[]} options
   */
  function onRolesChange(options) {
    setUserData({ ...userData, roles: options.map((option) => option.value) });
  }

  /**
   * This method will create and return the FormData object for submission
   * @returns {FormData}
   */
  function getSubmitData() {
    const formData = new FormData();
    formData.append("name", userData.name);
    formData.append("email", userData.email);
    formData.append("password", userData.password);
    formData.append("employee_id", userData.employeeID);
    userData.roles.forEach((role, index) => {
      formData.append(`roles[${index}]`, role);
    });
    if (user) {
      formData.append("_method", "PUT");
    }
    return formData;
  }

  /**
   * This method will submit the form to the EVA API and handles its response
   */
  async function onSubmit() {
    setSubmitErros(null);

    try {
      const response = await EVAApi.request({
        url: user ? `users/${user}` : "users",
        method: "POST",
        data: getSubmitData(),
      });

      if (response.status === 200 && response.data?.success) {
        navigate(USERS_PAGE_URL);
      }
    } catch (error) {
      if (error.response?.data) {
        setSubmitErros(error.response.data);
      }
    }
  }

  return (
    <UserPageView
      user={user}
      userData={userData}
      userUpdateSelfOnly={userUpdateSelfOnly}
      roles={roles}
      employees={employees}
      onNameChange={onNameChange}
      onEmailChange={onEmailChange}
      onPasswordChange={onPasswordChange}
      onEmployeeIDChange={onEmployeeIDChange}
      onRolesChange={onRolesChange}
      onSubmit={onSubmit}
      submitErrors={submitErrors}
    />
  );
}

export default UserPage;
