import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import EVAApi from "../../../../apis/EVAApi";
import { useLanguageContext } from "../../../../context/LanguageContext";
import AnnouncementPageView from "./AnnouncementPageView";

function AnnouncementPage() {
  const ANNOUNCEMENTS_PAGE_URL = "/dashboard/announcements";

  const navigate = useNavigate();
  const { announcement } = useParams();
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();

  const [announcementData, setAnnouncementData] = useState({
    fetched: false,
    title: "",
    activeFrom: "",
    activeUntil: "",
    description: "",
  });
  const [submitErrors, setSubmitErrors] = useState(null);

  /**
   * This method will fetch the initial data from the API
   */
  const fetchAnnouncementData = useCallback(async () => {
    try {
      const response = await EVAApi.get(`announcements/${announcement}`);
      if (response.status === 200 && response.data?.data) {
        setAnnouncementData({
          fetched: true,
          title: response.data.data.title,
          activeFrom: response.data.data.active_from,
          activeUntil: response.data.data.active_until,
          description: response.data.data.description,
        });
      }
    } catch (error) {
      navigate(ANNOUNCEMENTS_PAGE_URL);
    }
  }, [announcement, navigate]);

  /**
   * This effect will initialize the page
   */
  useEffect(() => {
    setDashboardTitle(
      announcement
        ? translate("entities.actions.edit", [
            { key: "entities", value: translate("entities.announcement") },
          ])
        : translate("entities.actions.create", [
            { key: "entities", value: translate("entities.announcement") },
          ])
    );

    if (announcement) {
      fetchAnnouncementData();
    }
  }, [setDashboardTitle, translate, announcement, fetchAnnouncementData]);

  /**
   * This method will update the title on input change
   * @param {event} e
   */
  function onTitleChange(e) {
    setAnnouncementData({ ...announcementData, title: e.target.value });
  }

  /**
   * This method will update the active from on input change
   * @param {event} e
   */
  function onActiveFromChange(e) {
    setAnnouncementData({ ...announcementData, activeFrom: e.target.value });
  }

  /**
   * This method will update the active until on input change
   * @param {event} e
   */
  function onActiveUntilChange(e) {
    setAnnouncementData({ ...announcementData, activeUntil: e.target.value });
  }

  /**
   * This method will update the description on input change
   * @param {event} e
   */
  function onDescriptionChange(e) {
    setAnnouncementData({ ...announcementData, description: e.target.value });
  }

  /**
   * This method will create and return the FormData object for submission
   * @returns {FormData}
   */
  function getSubmitData() {
    const formData = new FormData();
    formData.append("title", announcementData.title);
    formData.append("active_from", announcementData.activeFrom);
    formData.append("active_until", announcementData.activeUntil);
    formData.append("description", announcementData.description);
    if (announcement) {
      formData.append("_method", "PUT");
    }
    return formData;
  }

  /**
   * This method will submit the form
   */
  async function onSubmit(event) {
    setSubmitErrors(null);

    try {
      const response = await EVAApi.request({
        url: announcement ? `announcements/${announcement}` : "announcements",
        method: "POST",
        data: getSubmitData(),
      });

      if (response.status === 200 && response.data?.success) {
        navigate(ANNOUNCEMENTS_PAGE_URL);
      }
    } catch (error) {
      if (error.response?.data) {
        setSubmitErrors(error.response.data);
      }
    }
  }

  return (
    <AnnouncementPageView
      announcement={announcement}
      announcementData={announcementData}
      onTitleChange={onTitleChange}
      onActiveFromChange={onActiveFromChange}
      onActiveUntilChange={onActiveUntilChange}
      onDescriptionChange={onDescriptionChange}
      onSubmit={onSubmit}
      submitErrors={submitErrors}
    />
  );
}

export default AnnouncementPage;
