import { useState } from "react";
import { useLanguageContext } from "../../context/LanguageContext";
import {
  StyledImageInputContainer,
  StyledImageInputImage,
  StyledImageInputInput,
  StyledImageInputOverlay,
} from "./ImageInputStyles";

function ImageInput({ width, height, src, alt, onImageChange }) {
  const { translate } = useLanguageContext();
  const [image, setImage] = useState(null);

  function handleChange(e) {
    setImage(e.target.files[0]);
    onImageChange(e);
  }

  return (
    <StyledImageInputContainer width={width} height={height}>
      <StyledImageInputImage
        src={
          image
            ? URL.createObjectURL(image)
            : src
            ? src
            : "/assets/images/image_placeholder.jpg"
        }
        alt={alt}
      />

      <StyledImageInputOverlay>
        <b>{translate("main.select")}</b>
        <StyledImageInputInput
          type="file"
          onChange={handleChange}
          accept="image/jpeg, image/png, image/jpg"
        />
      </StyledImageInputOverlay>
    </StyledImageInputContainer>
  );
}

export default ImageInput;
