import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import EVAApi from "../../../../apis/EVAApi";
import { useLanguageContext } from "../../../../context/LanguageContext";
import CompanyPageView from "./CompanyPageView";

function CompanyPage() {
  const COMPANIES_PAGE_URL = "/dashboard/companies";

  const navigate = useNavigate();
  const { company } = useParams();
  const { translate } = useLanguageContext();
  const { setDashboardTitle } = useOutletContext();

  const [companyData, setCompanyData] = useState({
    fetched: false,
    name: "",
    contact: "",
    contactDetails: "",
  });
  const [submitErrors, setSubmitErrors] = useState(null);

  /**
   * This method will fetch the company data from the API
   */
  const fetchCompanyData = useCallback(async () => {
    try {
      const response = await EVAApi.get(`companies/${company}`);
      if (response.status === 200 && response.data?.data) {
        setCompanyData({
          fetched: true,
          name: response.data.data.name,
          contact: response.data.data.contact,
          contactDetails: response.data.data.contact_details,
        });
      }
    } catch (error) {
      navigate(COMPANIES_PAGE_URL);
    }
  }, [company, navigate]);

  /**
   * This effect will initialize the page
   */
  useEffect(() => {
    setDashboardTitle(
      company
        ? translate("entities.actions.edit", [
            { key: "entities", value: translate("entities.company") },
          ])
        : translate("entities.actions.create", [
            { key: "entities", value: translate("entities.company") },
          ])
    );

    if (company) {
      fetchCompanyData();
    }
  }, [setDashboardTitle, translate, company, fetchCompanyData]);

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setCompanyData({ ...companyData, name: e.target.value });
  }

  /**
   * This method will update the contact on input change
   * @param {event} e
   */
  function onContactChange(e) {
    setCompanyData({ ...companyData, contact: e.target.value });
  }

  /**
   * This method will update the contact details on input change
   * @param {event} e
   */
  function onContactDetailsChange(e) {
    setCompanyData({ ...companyData, contactDetails: e.target.value });
  }

  /**
   * This method will create and return the FormData object for submission
   * @returns {FormData}
   */
  function getSubmitData() {
    const formData = new FormData();
    formData.append("name", companyData.name);
    formData.append("contact", companyData.contact);
    formData.append("contact_details", companyData.contactDetails);
    if (company) {
      formData.append("_method", "PUT");
    }
    return formData;
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    setSubmitErrors(null);

    try {
      const response = await EVAApi.request({
        url: company ? `companies/${company}` : "companies",
        method: "POST",
        data: getSubmitData(),
      });

      if (response.status === 200 && response.data?.success) {
        navigate(COMPANIES_PAGE_URL);
      }
    } catch (error) {
      if (error.response?.data) {
        setSubmitErrors(error.response.data);
      }
    }
  }

  return (
    <CompanyPageView
      company={company}
      companyData={companyData}
      onNameChange={onNameChange}
      onContactChange={onContactChange}
      onContactDetailsChange={onContactDetailsChange}
      onSubmit={onSubmit}
      submitErrors={submitErrors}
    />
  );
}

export default CompanyPage;
