/**
 * This method will return if the given user has the given permission or not
 * @param {object} user
 * @param {string} permission
 * @return {bool}
 */
export function userHasPermission(user, permission) {
  if (!user) {
    return false;
  }

  if (user.role_type === "superadmin") {
    return true;
  }

  for (let i = 0; i < user.roles.length; i++) {
    for (let j = 0; j < user.roles[i].permissions.length; j++) {
      if (user.roles[i].permissions[j].name === permission) {
        return true;
      }
    }
  }

  return false;
}
