import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import EVAApi from "../../../apis/EVAApi";
import { useLanguageContext } from "../../../context/LanguageContext";
import DataTable from "../DataTable";
import DataTableColumn from "../DataTableColumn";
import defaultDataTableFetch from "../DataTableFetch";
import DataTableHeaderAction from "../DataTableHeaderAction";
import DataTableRowAction from "../DataTableRowAction";
import { useAuthContext } from "../../../context/AuthContext";
import { userHasPermission } from "../../../helpers/Auth";

function UsersDataTable() {
  const authContext = useAuthContext();
  const navigate = useNavigate();
  const { translate } = useLanguageContext();
  const [data, setData] = useState(null);
  const [filtering, setFiltering] = useState({});

  /**
   * This callback will get the rows for this dataTable
   */
  const getData = useCallback(async (filtering) => {
    const newData = await defaultDataTableFetch("users", filtering);
    setData(newData);
    setFiltering(filtering);
  }, []);

  /**
   * This method returns a list of datatable columns
   * @returns {object[]}
   */
  function getColumns() {
    return [
      DataTableColumn.create()
        .setName("name")
        .setLabel(translate("validation.attributes.name"))
        .setSelector((row) => row.name),
      DataTableColumn.create()
        .setName("email")
        .setLabel(translate("validation.attributes.email"))
        .setSelector((row) => row.email),
    ];
  }

  /**
   * This method returns a list of datatable row actions
   * @returns {object[]}
   */
  function getRowActions() {
    return [
      DataTableRowAction.create()
        .setIcon((row) => faPen)
        .setCallback(handleEdit)
        .setDisableOn(
          (row) =>
            row.email === "superadmin@evasolutions.eu" ||
            (!userHasPermission(authContext.auth.user, "users.update") &&
              !userHasPermission(authContext.auth.user, "users.update.self"))
        ),
      DataTableRowAction.create()
        .setIcon((row) => faTrash)
        .setCallback(handleDelete)
        .setDisableOn(
          (row) =>
            row.email === "superadmin@evasolutions.eu" ||
            row.id === authContext.auth.user.id ||
            !userHasPermission(authContext.auth.user, "users.delete")
        ),
    ];
  }

  /**
   * This method returns a list of datatable header actions
   * @returns {object[]}
   */
  function getHeaderActions() {
    return [
      DataTableHeaderAction.create()
        .setLabel(translate("entities.roles"))
        .setCallback(handleRoles)
        .setDisabled(
          authContext.auth.user?.company.type === "superadmin" ||
            (!userHasPermission(authContext.auth.user, "roles.read") &&
              !userHasPermission(authContext.auth.user, "roles.read.self"))
        ),
      DataTableHeaderAction.create()
        .setIcon(faPlus)
        .setCallback(handleAdd)
        .setDisabled(!userHasPermission(authContext.auth.user, "users.create")),
    ];
  }

  function handleRoles() {
    navigate("/dashboard/users/roles");
  }

  /**
   * This method will try and add a new record
   */
  function handleAdd() {
    navigate("/dashboard/users/create");
  }

  /**
   * This method will try and edit the given row
   * @param {object} row
   */
  function handleEdit(row) {
    navigate(`/dashboard/users/${row.id}/edit`);
  }

  /**
   * This method will try and delete the given row
   * @param {object} row
   * @returns
   */
  async function handleDelete(row) {
    if (!window.confirm(translate("confirmations.delete.user"))) {
      return;
    }

    const response = await EVAApi.delete(`users/${row.id}`);
    if (response.status === 200 && response.data?.success) {
      getData(filtering);
    }
  }

  /**
   * Return the base Datatable component
   */
  return (
    <DataTable
      data={data}
      getData={getData}
      columns={getColumns()}
      rowActions={getRowActions()}
      headerActions={getHeaderActions()}
      defaultSort="asc"
    />
  );
}

export default UsersDataTable;
