import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Card from "../../../../../components/card/Card";
import {
  StyledOrdersPreparePageName,
  StyledOrdersPreparePageTable,
  StyledOrdersPreparePageTd,
  StyledOrdersPreparePageTr,
} from "./OrdersPreparePageStyles";
import { StyledIconButton } from "../../../../../styles/ButtonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function OrdersPreparePageView({ prepareList, onPrepareDish }) {
  return (
    <Card>
      <StyledOrdersPreparePageTable>
        <tbody>
          {prepareList.map((prepareListItem, index) => (
            <StyledOrdersPreparePageTr key={index}>
              <StyledOrdersPreparePageTd small>
                {prepareListItem.total}
              </StyledOrdersPreparePageTd>

              <StyledOrdersPreparePageTd>
                <div>
                  <b>{prepareListItem.dish.name}</b>
                </div>
                <StyledOrdersPreparePageName>
                  {prepareListItem.employees.map(
                    (employeeItem, index) =>
                      `${employeeItem.employee.name} (${employeeItem.amount})` +
                      (index < prepareListItem.employees.length - 1 ? ", " : "")
                  )}
                </StyledOrdersPreparePageName>
              </StyledOrdersPreparePageTd>

              <StyledOrdersPreparePageTd small>
                <StyledIconButton
                  disabled={prepareListItem.status === "done"}
                  onClick={() => {
                    onPrepareDish(prepareListItem.dish.id);
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </StyledIconButton>
              </StyledOrdersPreparePageTd>
            </StyledOrdersPreparePageTr>
          ))}
        </tbody>
      </StyledOrdersPreparePageTable>
    </Card>
  );
}

export default OrdersPreparePageView;
