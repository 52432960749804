import styled from "styled-components";

export const StyledInput = styled.input`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.disabled
      : props.theme.colors.primary.lightest};
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.default};
  border: 1px solid transparent;
  transition: background-color
      ${(props) => props.theme.transition.duration.default},
    color ${(props) => props.theme.transition.duration.default},
    border-color ${(props) => props.theme.transition.duration.default};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.on.disabled
      : props.theme.colors.on.primary};
  outline: none;
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  min-width: ${(props) => props.minWidth};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-weight: initial;

  ::placeholder {
    color: ${(props) => props.theme.colors.on.primary};
    opacity: 0.3;
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.accent.dark};
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.accent.normal};
  }
`;
