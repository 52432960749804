import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import EVAApi from "../../../../../apis/EVAApi";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import OrdersPreparePageView from "./OrdersPreparePageView";

function OrdersPreparePage() {
  const ORDERS_PAGE_URL = "/dashboard/canteen/orders";

  const navigate = useNavigate();
  const { setDashboardTitle } = useOutletContext();
  const { translate } = useLanguageContext();

  const [prepareList, setPrepareList] = useState([]);

  /**
   * This method will convert the given orders into a preparation list format
   *
   * @param {object[]} orders
   */
  const convertOrders = useCallback(
    (orders) => {
      const newPrepareList = [];
      if (orders.filter((order) => order.status !== "done").length <= 0) {
        navigate("/dashboard/canteen/orders/log");
      }

      orders.forEach((order) => {
        order.order_dishes.forEach((orderDish) => {
          const prepareListIndex = newPrepareList.findIndex(
            (prepareListItem) => prepareListItem.dish.id === orderDish.dish.id
          );

          orderDish = { ...orderDish, order: order };

          if (prepareListIndex < 0) {
            newPrepareList.push({
              dish: orderDish.dish,
              status: orderDish.status,
              total: 1,
              employees: [{ employee: order.employee, amount: 1 }],
            });
          } else {
            newPrepareList[prepareListIndex].total++;

            const employeeIndex = newPrepareList[
              prepareListIndex
            ].employees.findIndex(
              (item) => item.employee.name === order.employee.name
            );

            if (employeeIndex < 0) {
              newPrepareList[prepareListIndex].employees.push({
                employee: order.employee,
                amount: 1,
              });
            } else {
              newPrepareList[prepareListIndex].employees[employeeIndex]
                .amount++;
            }
          }
        });
      });

      setPrepareList(newPrepareList);
    },
    [navigate]
  );

  /**
   * This method will fetch the orders from the API
   */
  const fetchOrders = useCallback(async () => {
    try {
      const response = await EVAApi.get("canteen/orders", {
        params: { with_dish_data: true, with_employee_data: true },
      });
      if (response.status === 200 && response.data?.success) {
        if (response.data.data.records.length > 0) {
          convertOrders(response.data.data.records);
        } else {
          navigate(ORDERS_PAGE_URL);
        }
      }
    } catch (error) {
      navigate(ORDERS_PAGE_URL);
    }
  }, [navigate, convertOrders]);

  /**
   * Useffect for initializing the component
   */
  useEffect(() => {
    setDashboardTitle(
      translate("entities.actions.prepare", [
        { key: "entities", value: translate("entities.orders") },
      ])
    );

    fetchOrders();
  }, [setDashboardTitle, translate, convertOrders, fetchOrders]);

  /**
   * This method will mark the given dish as done using the API
   * @param {integer} dish
   */
  async function onPrepareDish(dish) {
    const response = await EVAApi.post(`canteen/orders/prepare/${dish}`, null, {
      params: { with_dish_data: true, with_employee_data: true },
    });
    if (response.status === 200 && response.data?.success) {
      convertOrders(response.data.data);
    }
  }

  return (
    <OrdersPreparePageView
      prepareList={prepareList}
      onPrepareDish={onPrepareDish}
    />
  );
}

export default OrdersPreparePage;
