import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import GlobalStyles from "./styles/GlobalStyles";
import { AutoThemeProvider } from "./context/ThemeContext";
import { LanguageProvider } from "./context/LanguageContext";
import AppRoutes from "./Routes";
import { ParameterProvider } from "./context/ParameterContext";

function App() {
  return (
    <AutoThemeProvider>
      <GlobalStyles />

      <BrowserRouter>
        <LanguageProvider>
          <AuthProvider>
            <ParameterProvider>
              <AppRoutes />
            </ParameterProvider>
          </AuthProvider>
        </LanguageProvider>
      </BrowserRouter>
    </AutoThemeProvider>
  );
}

export default App;
