import Select from "react-select";
import styled from "styled-components";

export const StyledSelect = styled(Select)`
  .react-select__container {
    outline: none;
  }

  .react-select__control {
    background-color: ${(props) =>
      props.isDisabled
        ? props.theme.colors.disabled
        : props.theme.colors.primary.lightest};
    border-radius: ${(props) => props.theme.radius.default};
    border: 1px solid transparent;
    transition: background-color
        ${(props) => props.theme.transition.duration.default},
      color ${(props) => props.theme.transition.duration.default},
      border-color ${(props) => props.theme.transition.duration.default};
    outline: none;
    box-shadow: none;
    pointer-events: ${(props) => (props.isDisabled ? "none" : "initial")};

    &--is-focused {
      border-color: ${(props) => props.theme.colors.accent.normal};
    }

    &:hover {
      border-color: ${(props) => props.theme.colors.accent.dark};
    }

    &:focus {
      border-color: ${(props) => props.theme.colors.accent.normal};
    }
  }

  .react-select__value-container {
    padding: ${(props) => props.theme.spacing.normal};
  }

  .react-select__input-container {
    margin: 0;
    padding: 0;
  }

  .react-select__placeholder {
    color: ${(props) =>
      props.isDisabled
        ? props.theme.colors.on.disabled
        : props.theme.colors.on.primary};
    opacity: 0.3;
  }

  .react-select__input {
    color: ${(props) =>
      props.isDisabled
        ? props.theme.colors.on.disabled
        : props.theme.colors.on.primary} !important;
  }

  .react-select__single-value {
    margin: 0;
    color: ${(props) =>
      props.isDisabled
        ? props.theme.colors.on.disabled
        : props.theme.colors.on.primary};
  }

  .react-select__menu {
    margin-top: 0;
    border: none;
    border-radius: ${(props) => props.theme.radius.default};
    overflow: hidden;
    background-color: ${(props) => props.theme.colors.primary.lightest};
  }

  .react-select__option {
    &--is-selected,
    &--is-focused {
      background-color: ${(props) =>
        props.theme.colors.primary.light} !important;
    }
  }

  .react-select__multi-value {
    background-color: ${(props) => props.theme.colors.primary.light};
    border-radius: ${(props) => props.theme.radius.small};
    overflow: hidden;
  }

  .react-select__multi-value__label {
    color: ${(props) => props.theme.colors.on.primary};
  }

  .react-select__multi-value__remove {
    &:hover {
      background-color: transparent;
      color: ${(props) => props.theme.colors.accent.dark};
    }
  }

  .react-select__clear-indicator {
    color: ${(props) =>
      props.isDisabled
        ? props.theme.colors.on.disabled
        : props.theme.colors.on.primary};
    padding: 0;

    &:hover {
      color: ${(props) => props.theme.colors.accent.dark};
      cursor: pointer;
    }
  }

  .react-select__clear-indicator svg {
    height: ${(props) => props.theme.font.size.default};
    width: ${(props) => props.theme.font.size.default};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    color: ${(props) =>
      props.isDisabled
        ? props.theme.colors.on.disabled
        : props.theme.colors.on.primary};
    padding: 0px ${(props) => props.theme.spacing.normal} 0px 0px;

    &:hover {
      color: ${(props) => props.theme.colors.accent.dark};
      cursor: pointer;
    }
  }

  .react-select__dropdown-indicator svg {
    height: ${(props) => props.theme.font.size.default};
    width: ${(props) => props.theme.font.size.default};
  }
`;
