import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

function CompanyTypeGuard({ type }) {
  const authContext = useAuthContext();
  const outletContext = useOutletContext();

  if (authContext.auth.user.company.type !== type) {
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet context={outletContext} />;
}

export default CompanyTypeGuard;
