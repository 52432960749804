import EVAApi from "../../apis/EVAApi";

/**
 * This method will fetch dataTable data and return it
 * @param {string} url
 * @param {string} search
 * @param {string[]} search_columns
 * @returns {JSON}
 */
export default async function defaultDataTableFetch(url, filtering, headers) {
  try {
    const response = await EVAApi.get(url, {
      headers: headers,
      params: filtering,
    });
    if (response.status === 200 && response.data?.data) {
      return response.data.data;
    }
  } catch (error) {
    if (
      error.response?.status === 404 &&
      error.response?.data?.success === true
    ) {
      return null;
    }
  }
}
