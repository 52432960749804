const DarkTheme = {
  colors: {
    primary: {
      darkest: "#111827",
      dark: "#1f2937",
      normal: "#374151",
      light: "#3e4858",
      lightest: "#4b5563",
    },

    accent: {
      dark: "#f97316",
      normal: "#fb923c",
    },

    disabled: "#6b7280",
    error: "#fb923c",

    on: {
      primary: "white",
      accent: "white",
      disabled: "rgba(255, 255, 255, 0.35)",
      error: "white",
    },
  },

  container: {
    size: {
      normal: "30px",
    },
  },

  spacing: {
    small: "5px",
    normal: "7.5px",
    large: "20px",
  },

  font: {
    size: {
      sm: "10px",
      default: "13.5px",
      lg: "20.25px",
      xl: "27px",
    },
  },

  radius: {
    small: "6.75px",
    default: "10px",
  },

  transition: {
    duration: {
      default: "300ms",
    },
  },

  shadow: {
    default:
      "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
  },
};

export default DarkTheme;
