import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import EVAApi from "../../../apis/EVAApi";
import { useLanguageContext } from "../../../context/LanguageContext";
import DataTable from "../DataTable";
import DataTableColumn from "../DataTableColumn";
import defaultDataTableFetch from "../DataTableFetch";
import DataTableHeaderAction from "../DataTableHeaderAction";
import DataTableRowAction from "../DataTableRowAction";
import { useAuthContext } from "../../../context/AuthContext";
import { userHasPermission } from "../../../helpers/Auth";

function RolesDataTable() {
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();
  const [data, setData] = useState(null);
  const [filtering, setFiltering] = useState({});

  /**
   * This callback will get the rows for this dataTable
   */
  const getData = useCallback(async (filtering) => {
    const newData = await defaultDataTableFetch("roles", filtering);
    setData(newData);
    setFiltering(filtering);
  }, []);

  /**
   * This method returns a list of datatable columns
   * @returns {object[]}
   */
  function getColumns() {
    return [
      DataTableColumn.create()
        .setName("name")
        .setLabel(translate("validation.attributes.name"))
        .setSelector((row) => row.name),
    ];
  }

  /**
   * This method returns a list of datatable row actions
   * @returns {object[]}
   */
  function getRowActions() {
    return [
      DataTableRowAction.create()
        .setIcon((row) => faPen)
        .setCallback(handleEdit)
        .setDisableOn(
          (row) => !userHasPermission(authContext.auth.user, "roles.update")
        ),
      DataTableRowAction.create()
        .setIcon((row) => faTrash)
        .setCallback(handleDelete)
        .setDisableOn(
          (row) => !userHasPermission(authContext.auth.user, "roles.delete")
        ),
    ];
  }

  /**
   * This method returns a list of datatable header actions
   * @returns {object[]}
   */
  function getHeaderActions() {
    return [
      DataTableHeaderAction.create()
        .setIcon(faPlus)
        .setCallback(handleAdd)
        .setDisabled(!userHasPermission(authContext.auth.user, "roles.create")),
    ];
  }

  /**
   * This method will try and add a new record
   */
  function handleAdd() {
    navigate("/dashboard/users/roles/create");
  }

  /**
   * This method will try and edit the given row
   * @param {object} row
   */
  function handleEdit(row) {
    navigate(`/dashboard/users/roles/${row.id}/edit`);
  }

  /**
   * This method will try and delete the given row
   * @param {object} row
   * @returns
   */
  async function handleDelete(row) {
    if (!window.confirm(translate("confirmations.delete.role"))) {
      return;
    }

    const response = await EVAApi.delete(`roles/${row.id}`);
    if (response.status === 200 && response.data?.success) {
      getData(filtering);
    }
  }

  /**
   * Return the base Datatable component
   */
  return (
    <DataTable
      data={data}
      getData={getData}
      columns={getColumns()}
      rowActions={getRowActions()}
      headerActions={getHeaderActions()}
      defaultSort="asc"
    />
  );
}

export default RolesDataTable;
