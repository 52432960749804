export default class DataTableHeaderAction {
  constructor() {
    this.icon = null;
    this.label = null;
    this.callback = null;
    this.disabled = false;
  }

  /**
   * Creates and returns a new instance of this class
   * @returns {DataTableHeaderAction}
   */
  static create() {
    return new DataTableHeaderAction();
  }

  /**
   * Used as a icon representation of this action
   * @param {IconDefinition} icon
   * @returns {this}
   */
  setIcon(icon) {
    this.icon = icon;
    return this;
  }

  /**
   * Used as a textual representation of this action
   * @param {string} label
   * @returns
   */
  setLabel(label) {
    this.label = label;
    return this;
  }

  /**
   * Used as a callback function whenever the action was pressed
   * @param {function()} callback
   * @returns {this}
   */
  setCallback(callback) {
    this.callback = callback;
    return this;
  }

  /**
   * Used to disable the action
   * @param {boolean} disabled
   * @returns {this}
   */
  setDisabled(disabled) {
    this.disabled = disabled;
    return this;
  }
}
