import { useTheme } from "styled-components";
import { StyledInput } from "../../../../../styles/InputStyles";
import InputWrapper from "../../../../../components/inputWrapper/InputWrapper";
import { StyledButton } from "../../../../../styles/ButtonStyles";
import Card from "../../../../../components/card/Card";
import { StyledFlexLayout } from "../../../../../styles/LayoutStyles";
import Select from "../../../../../components/select/Select";
import { useLanguageContext } from "../../../../../context/LanguageContext";

function RolePageView({
  role,
  roleData,
  permissions,
  onNameChange,
  onPermissionsChange,
  onSubmit,
  submitErrors,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  const permissionsInputOptions = permissions.records.map((permission) => {
    return { value: permission.id, label: permission.name };
  });

  return permissions.fetched && (!role || roleData.fetched) ? (
    <Card header={translate("main.data")}>
      <StyledFlexLayout
        direction="column"
        margin={`0px 0px ${theme.spacing.normal} 0px`}
      >
        <InputWrapper
          label={translate("validation.attributes.name")}
          required
          error={submitErrors?.errors?.name?.[0]}
        >
          <StyledInput
            type="text"
            placeholder={translate("validation.placeholders.name.role")}
            value={roleData.name}
            onChange={onNameChange}
          />
        </InputWrapper>

        {/* Allergen select */}
        <InputWrapper
          label={translate("entities.permissions")}
          margin={`0px 0px ${theme.spacing.normal} 0px`}
        >
          <Select
            isMulti
            options={permissionsInputOptions}
            value={permissionsInputOptions.filter((option) =>
              roleData.permissions.find(
                (rolePermission) => option.value === rolePermission
              )
            )}
            onChange={onPermissionsChange}
          />
        </InputWrapper>
      </StyledFlexLayout>

      <StyledButton onClick={onSubmit}>{translate("main.save")}</StyledButton>
    </Card>
  ) : (
    ""
  );
}

export default RolePageView;
